import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { WarningPing } from 'shared/ui/WarningPing';
import { showNotification } from 'app/providers/NotificationsProvider';
import { Form } from 'antd';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { PHONE_NUMBER_REGEX } from 'shared/utils/regex';
import { PhoneInput } from 'shared/ui/PhoneInput';
import { LeadData, useCreateLeadMutation, useUpdateLeadMutation } from 'entities/Lead';
import { AppLanguage } from 'app/config/i18Config/types';
import { getLeadFormModalState } from '../model/selectors/getLeadFormModalState';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { leadFormModalActions } from '../model/slice/leadFromModalSlice';
import { LeadFormModalMode } from '../model/types';
import { useGetAllWarehousesQuery } from 'entities/Warehouse';
import { InputNumber } from 'shared/ui/InputNumber';
import { Checkbox } from 'shared/ui/Checkbox';
import { getLoggedUserData, getUserName } from 'entities/User';
import { transformEmptyStringToNull } from 'shared/utils/helpers/transformEmptyStringToNull';

export const LeadFormModal: FC = memo(() => {
  const dispatch = useAppDispatch();

  const { t } = useAppTranslation(['contracts', 'common']);

  const leadFormState = useAppSelector(getLeadFormModalState);
  const loggedUserData = useAppSelector(getLoggedUserData);

  const [form] = Form.useForm();

  const [createLead, { isLoading: isCreationLoading }] = useCreateLeadMutation();
  const [updateLead, { isLoading: isUpdationLoading }] = useUpdateLeadMutation();
  const { data: warehouses } = useGetAllWarehousesQuery();

  const isLoading = isUpdationLoading || isCreationLoading;

  const isEditMode = leadFormState.mode === LeadFormModalMode.LEAD_EDIT;

  const closeLeadFormModal = useCallback((): void => {
    dispatch(leadFormModalActions.setOpenLeadFormModal({ mode: null, lead: null }));
  }, [dispatch]);

  useEffect(() => {
    if (leadFormState?.lead) {
      form.setFieldsValue(leadFormState.lead);
    } else {
      form.resetFields();
    }
  }, [form, leadFormState?.lead]);

  const handleCreateLead = useCallback(
    async (leadData: LeadData) => {
      try {
        const params = transformEmptyStringToNull(leadData);

        await createLead({
          ...params,
          isThirdPartyAuthorized: true,
          note: `${t('Lead has been created by')} ${getUserName(loggedUserData)}`,
        }).unwrap();

        closeLeadFormModal();
        showNotification('info', t('Success', { ns: 'common' }), t('Lead has been successfully created'));
      } catch {
        showNotification('error', t('Error', { ns: 'common' }), t('Error when creating lead', { ns: 'common' }));
      }
    },
    [closeLeadFormModal, createLead, loggedUserData, t],
  );

  const handleUpdateLead = useCallback(
    async (leadData: LeadData) => {
      try {
        if (leadFormState?.lead) {
          const { leadId } = leadFormState.lead;

          const params = transformEmptyStringToNull(leadData);

          await updateLead({
            ...params,
            leadId,
            note: `${t('Lead has been updated by')} ${getUserName(loggedUserData)}`,
          }).unwrap();

          closeLeadFormModal();

          showNotification('info', t('Success', { ns: 'common' }), t('Lead has been successfully updated'));
        }
      } catch (error: CustomAny) {
        const errorMessage = t(error?.data?.[0] || 'Error when updating lead', { ns: 'common' });

        showNotification('error', t('Error', { ns: 'common' }), errorMessage);
      }
    },
    [closeLeadFormModal, leadFormState.lead, loggedUserData, t, updateLead],
  );

  const validateContactInfo = useCallback(
    (leadData: LeadData): boolean => {
      if (!leadData.email && !leadData.phone) {
        form.setFields([
          {
            name: 'email',
            errors: [t('Please enter either email or phone!')],
          },
          {
            name: 'phone',
            errors: [t('Please enter either email or phone!')],
          },
        ]);
        return false;
      }

      return true;
    },
    [form, t],
  );

  const handleSubmit = useCallback(
    async (leadData: LeadData) => {
      if (validateContactInfo(leadData)) {
        switch (leadFormState.mode) {
          case LeadFormModalMode.LEAD_CREATE:
            await handleCreateLead(leadData);
            break;
          case LeadFormModalMode.LEAD_EDIT:
            await handleUpdateLead(leadData);
            break;
        }
      }
    },
    [handleCreateLead, handleUpdateLead, leadFormState.mode, validateContactInfo],
  );

  const languages = [
    { label: 'English', value: AppLanguage.en },
    { label: 'Deutsch', value: AppLanguage.de },
    { label: 'Polski', value: AppLanguage.pl },
  ];

  const warehouseOptions = useMemo(() => {
    return (
      warehouses?.map((warehouse) => ({
        label: warehouse.name,
        value: warehouse.warehouseId,
      })) || []
    );
  }, [warehouses]);

  return (
    <>
      <Modal zIndex={10000} className="max-w-[400px]" isOpen={Boolean(leadFormState.mode)} onClose={closeLeadFormModal}>
        <Form form={form} layout="vertical" onFinish={handleSubmit} className="flex flex-col">
          <div className="flex items-center mb-2">
            <WarningPing type="warning" />
            <div className="font-semibold text-xl">{t(`${isEditMode ? 'Edit Lead' : 'Create Lead'}`)}</div>
          </div>

          <div className="my-5">
            <Form.Item name="language" label={t('Language')} rules={[{ required: true, message: t('Please select offer language!') }]}>
              <Select placeholder={t('Select offer language')} options={languages} bordered />
            </Form.Item>

            <Form.Item label={t('Email')} name="email" rules={[{ type: 'email', message: t('Please enter correct email!') }]}>
              <Input placeholder={t('Please, enter email')} bordered />
            </Form.Item>

            <Form.Item
              label={t('Phone')}
              name="phone"
              rules={[{ pattern: PHONE_NUMBER_REGEX, message: t('Please provide a valid phone number!') }]}
            >
              <PhoneInput placeholder={t('Please, enter your phone number')} bordered initialValue={leadFormState.lead?.phone} />
            </Form.Item>

            <Form.Item label={t('Name')} name="firstName" rules={[{ min: 2, message: t('Name must be at least 2 characters!') }]}>
              <Input placeholder={t('Please, enter name')} bordered />
            </Form.Item>

            <Form.Item label={t('Last name')} name="lastName" rules={[{ min: 2, message: t('Last name must be at least 2 characters!') }]}>
              <Input placeholder={t('Please, enter last name')} bordered />
            </Form.Item>

            <Form.Item label={t('Warehouse')} name="warehouseId">
              <Select options={warehouseOptions} placeholder={t('Please, select warehouse')} bordered allowClear />
            </Form.Item>

            <Form.Item label={t('Box size')} name="square">
              <InputNumber placeholder={t('Please, enter box size')} bordered />
            </Form.Item>

            <Form.Item name="isAutoMailingEnabled" valuePropName="checked" noStyle>
              <Checkbox name="isAutoMailingEnabled">{t('Enable Automailing')}</Checkbox>
            </Form.Item>
          </div>

          <div className="flex justify-end gap-3">
            <Button theme="secondary" onClick={closeLeadFormModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>

            <Button type="submit" isLoading={isLoading}>
              {t('Save', { ns: 'common' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
});
