import { UserPermissions } from 'entities/User/model/types';

export const AUTH_LOGIN_PATH = '/authorization/login';
export const AUTH_LOGOUT_PATH = '/authorization/logout';
export const AUTH_ME_PATH = '/authorization/me';
export const AUTH_RESET_PASSWORD_PATH = '/authorization/reset-password';
export const USERS_PATH = '/users';

export const permissionPresets = {
  standard: [
    UserPermissions.REFUND_DEPOSITS,
    UserPermissions.MARK_AS_PAID_INVOICE,
    UserPermissions.MARK_AS_REFUNDED,
    UserPermissions.REPLENISH_BALANCE,
    UserPermissions.CHARGE_INVOICE,
    UserPermissions.REFUND_CREDITED_PAYMENTS,
  ],
  extended: [
    UserPermissions.REFUND_DEPOSITS,
    UserPermissions.REFUND_ACTIVE_PAYMENTS,
    UserPermissions.REFUND_CREDITED_PAYMENTS,
    UserPermissions.MARK_AS_PAID_INVOICE,
    UserPermissions.MARK_AS_REFUNDED,
    UserPermissions.CREDIT_INVOICE,
    UserPermissions.CREDIT_PAYMENT,
    UserPermissions.REPLENISH_BALANCE,
    UserPermissions.REDUCE_BALANCE,
    UserPermissions.MANAGE_AUTOCHARGES,
    UserPermissions.CHARGE_INVOICE,
    UserPermissions.CREATE_UNITS,
    UserPermissions.EDIT_UNITS,
    UserPermissions.DELETE_UNITS,
    UserPermissions.CLOSE_CONTRACT_EXTENDED,
  ],
};
