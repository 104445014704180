import React, { FC, memo, useCallback, useMemo } from 'react';
import { Collapse } from 'antd';
import { Contract } from 'entities/Contract';
import { useAppTranslation } from 'app/config/i18Config/hooks';

import s from './PaymentCard.module.scss';
import classNames from 'classnames';
import { BulletsTable } from 'shared/ui/BulletsTable';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { useGetCurrencySymbol } from 'app/appState';
import { ReactComponent as CollapseArrow } from 'shared/assets/icons/CollapseArrow.svg';
import { useRole } from 'entities/User';
import { Button } from 'shared/ui/Button';
import { MarkDepositAsRefunded } from 'features/MarkDepositAsRefunded';
import { ChargeDeposit } from 'features/ChargeDeposit';
import { Payment } from 'entities/Payment';
import { DepositPaymentStatus } from 'widgets/ContractDetailsDrawer';

interface DepositTabProps {
  contract: Contract;
  depositPayment?: Payment;
}

export const DepositTab: FC<DepositTabProps> = memo((props) => {
  const { contract, depositPayment } = props;
  const { deposit } = contract;

  const { t } = useAppTranslation('contracts');

  const currencySymbol = useGetCurrencySymbol();

  const { isManagement } = useRole();

  const depositPaymentStatus = useMemo(() => {
    if (deposit?.isRefunded) {
      return DepositPaymentStatus.REFUNDED;
    }
    if (depositPayment?.creditNote) {
      return DepositPaymentStatus.CANCELLED;
    }
    if (depositPayment) {
      return DepositPaymentStatus.PAID;
    } else {
      return DepositPaymentStatus.NOT_PAID;
    }
  }, [deposit?.isRefunded, depositPayment]);

  const DepositContent = useCallback((): JSX.Element => {
    return (
      <div>
        <BulletsTable
          theme="clear"
          rows={[
            {
              label: t('Deposit amount'),
              value: `${roundNumber(deposit?.initialDepositAmount)} ${currencySymbol}`,
            },
            {
              label: t('Current deposit amount'),
              value: `${roundNumber(deposit?.depositAmount)} ${currencySymbol}`,
            },
          ]}
        />

        <div className="flex flex-col gap-3 mt-6">
          {isManagement &&
            deposit &&
            !deposit?.isRefunded &&
            (contract.leaveDate ? (
              <>
                <div className="flex flex-col gap-3">
                  <MarkDepositAsRefunded
                    depositId={deposit.depositId}
                    action={<Button theme="secondary">{t('Mark as refunded')}</Button>}
                  />
                </div>
              </>
            ) : (
              <div className="flex flex-col gap-3">
                <ChargeDeposit
                  depositId={deposit.depositId}
                  amount={deposit.depositAmount}
                  action={
                    <Button
                      theme="primary"
                      isDisabled={
                        deposit.depositAmount === 0 ||
                        [DepositPaymentStatus.NOT_PAID, DepositPaymentStatus.CANCELLED, DepositPaymentStatus.REFUNDED].includes(
                          depositPaymentStatus,
                        )
                      }
                    >
                      {t('Charge from deposit')}
                    </Button>
                  }
                />
              </div>
            ))}
        </div>

        <div className="flex justify-end mt-3">
          <Button theme="clear">{t('Show deposit history')}</Button>
        </div>
      </div>
    );
  }, [t, deposit, currencySymbol, isManagement, contract.leaveDate, depositPaymentStatus]);

  return (
    <div>
      {deposit ? (
        <>
          <div className="space-y-3">
            <Collapse
              defaultActiveKey={deposit.depositId}
              className={classNames(s.collapse, {
                [s.refundedOrCredited]: deposit.isRefunded,
              })}
              items={[
                {
                  key: deposit.depositId,
                  label: (
                    <>
                      <div className="flex justify-between font-semibold text-lg">
                        {t('Deposit')} - {t(depositPaymentStatus)}
                        {/* <div className="text-accent">
                        // TO DO: add deposit fetch in this component to get relation deposit -> invoiceItem -> invoice to get invoice documentNumber
                          {t('Invoice')} {deposit?.invoiceItem?.invoice?.documentNumber}
                        </div> */}
                      </div>
                    </>
                  ),
                  children: <DepositContent />,
                },
              ]}
              expandIcon={(panelProps) => (
                <CollapseArrow
                  className={classNames('ease-linear duration-200', {
                    '-rotate-90 fill-primaryLight': panelProps.isActive,
                    'rotate-90 fill-secondaryAccent': !panelProps.isActive,
                  })}
                />
              )}
              expandIconPosition="end"
            />
          </div>
        </>
      ) : (
        <div className="font-semibold text-lg pt-7 pb-4 text-center">{t('Not applied')}</div>
      )}
    </div>
  );
});
