import { BookingLayout, GlobalLayout, MainLayout } from 'app/layouts';
import { getInfoFromToken, isAuthKeyEmpty, UserRoles } from 'entities/User';
import { AdminPanelPage } from 'pages/AdminPanelPage';
import { BookingPage } from 'pages/BookingPage';
import { BoxesPage } from 'pages/BoxesPage';
import { CheckoutPage } from 'pages/CheckoutPage';
import { CitiesPage } from 'pages/CitiesPage';
import { ContractsPage } from 'pages/ContractsPage';
import { ForgotPasswordPage } from 'pages/ForgotPasswordPage';
import { InvoicesPage } from 'pages/InvoicesPage';
import { LoginPage } from 'pages/LoginPage';
import { MyBoxesPage } from 'pages/MyBoxesPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { PaymentFailedPage } from 'pages/PaymentFailedPage';
import { PaymentSuccessPage } from 'pages/PaymentSuccessPage';
import { ReservationsPage } from 'pages/ReservationsPage';
import { ResetPasswordPage } from 'pages/ResetPasswordPage';
import { UserContractsPage } from 'pages/UserContractsPage';
import { UsersPage } from 'pages/UsersPage';
import { WarehousesPage } from 'pages/WarehousesPage';
import { createBrowserRouter, LoaderFunctionArgs, redirect, redirectDocument, RouteObject } from 'react-router-dom';
import { ProfilePage } from 'pages/ProfilePage';
import { REDIRECTED_FROM_URL_KEY } from './constants';
import { AppRoutes } from './types';
import { NotesPage } from 'pages/NotesPage';
import { LateChargeSettingsPage } from 'pages/LateChargeSettingsPage';
import { CreditNotesPage } from 'pages/CreditNotesPage';
import { InvoicesInsurancePage } from 'pages/InvoicesInsurancePage';
import { InsuranceSettingsPage } from 'pages/InsuranceSettingsPage';

const protectedRouteLoader =
  (allowedRoles: UserRoles[]) =>
  ({ request }: LoaderFunctionArgs): Nullable<Response> => {
    const isAuthorized = !isAuthKeyEmpty();
    const userRole = getInfoFromToken()?.role;

    const hasAccess = userRole ? allowedRoles.includes(userRole) : false;

    if (!isAuthorized || !userRole || !hasAccess) {
      sessionStorage.setItem(REDIRECTED_FROM_URL_KEY, request.url);

      return redirect(AppRoutes.LOGIN);
    }
    sessionStorage.removeItem(REDIRECTED_FROM_URL_KEY);

    return null;
  };

const rootRouteLoader = (): Response => {
  const userRole = getInfoFromToken()?.role;

  const redirectedFromURL = sessionStorage.getItem(REDIRECTED_FROM_URL_KEY);

  const redirectionMap: Record<UserRoles, AppRoutes> = {
    [UserRoles.SUPERUSER]: AppRoutes.WAREHOUSES,
    [UserRoles.MANAGER]: AppRoutes.WAREHOUSES,
    [UserRoles.CUSTOMER]: AppRoutes.MY_BOXES,
    [UserRoles.STAFF]: AppRoutes.BOXES,
    [UserRoles.PARTNER]: AppRoutes.WAREHOUSES,
  };

  return redirectedFromURL ? redirectDocument(redirectedFromURL) : redirect(userRole ? redirectionMap[userRole] : AppRoutes.BOOKING);
};

const loginPageLoader = (): Nullable<Response> => {
  const isAuthorized = !isAuthKeyEmpty();

  if (isAuthorized) {
    return redirect(AppRoutes.HOME);
  }

  return null;
};

const bookingRoutes: RouteObject[] = [
  {
    index: true,
    Component: BookingPage,
  },
];

const mainRoutes: RouteObject[] = [
  {
    path: AppRoutes.HOME,
    Component: MainLayout,
    children: [
      {
        index: true,
        loader: rootRouteLoader,
      },
      {
        path: AppRoutes.WAREHOUSES,
        Component: WarehousesPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.STAFF, UserRoles.PARTNER]),
      },
      {
        path: AppRoutes.CITIES,
        Component: CitiesPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER]),
      },
      {
        path: AppRoutes.USERS,
        Component: UsersPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER]),
      },
      {
        path: `${AppRoutes.USERS}/:userId/contracts`,
        Component: UserContractsPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER]),
      },
      {
        path: AppRoutes.CONTRACTS,
        Component: ContractsPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER]),
      },
      {
        path: AppRoutes.RESERVATIONS,
        Component: ReservationsPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER]),
      },
      {
        path: AppRoutes.INVOICES,
        Component: InvoicesPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER]),
      },
      {
        path: AppRoutes.CREDIT_NOTES,
        Component: CreditNotesPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER]),
      },
      {
        path: AppRoutes.INSURANCE,
        Component: InvoicesInsurancePage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER]),
      },
      {
        path: AppRoutes.BOXES,
        Component: BoxesPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.STAFF, UserRoles.PARTNER]),
      },
      {
        path: AppRoutes.NOTES,
        Component: NotesPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER]),
      },
      {
        path: AppRoutes.TASKS,
        Component: null,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER]),
      },
      {
        path: AppRoutes.GENERAL,
        Component: null,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER]),
      },
      {
        path: AppRoutes.BILLING_DOCUMENTS,
        Component: null,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER]),
      },
      {
        path: AppRoutes.INSURANCE_SETTINGS,
        Component: InsuranceSettingsPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER]),
      },
      {
        path: AppRoutes.LATE_CHARGE_SETTINGS,
        Component: LateChargeSettingsPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER]),
      },
      {
        path: AppRoutes.MY_BOXES,
        Component: MyBoxesPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.CUSTOMER, UserRoles.STAFF, UserRoles.PARTNER]),
      },
      {
        path: AppRoutes.GLOBAL_SETTINGS,
        Component: null,
        loader: protectedRouteLoader([UserRoles.SUPERUSER]),
      },
      {
        path: AppRoutes.ADMIN_PANEL,
        Component: AdminPanelPage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER]),
      },
      {
        path: `${AppRoutes.PROFILE}/:userId`,
        Component: ProfilePage,
        loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.CUSTOMER, UserRoles.STAFF, UserRoles.PARTNER]),
      },
    ],
  },
  {
    path: AppRoutes.BOOKING,
    Component: BookingLayout,
    children: bookingRoutes,
  },
  {
    path: AppRoutes.LOGIN,
    Component: LoginPage,
    loader: loginPageLoader,
  },
  {
    path: AppRoutes.FORGOT_PASSWORD,
    Component: ForgotPasswordPage,
    loader: loginPageLoader,
  },
  {
    path: `${AppRoutes.RESET_PASSWORD}/:resetPasswordId`,
    Component: ResetPasswordPage,
    loader: loginPageLoader,
  },
  {
    path: AppRoutes.NOT_FOUND,
    Component: NotFoundPage,
  },
  {
    path: AppRoutes.CHECKOUT,
    Component: CheckoutPage,
    loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.CUSTOMER, UserRoles.PARTNER]),
  },
  {
    path: AppRoutes.PAYMENT_SUCCESS,
    Component: PaymentSuccessPage,
    loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.CUSTOMER, UserRoles.PARTNER]),
  },
  {
    path: AppRoutes.PAYMENT_FAILED,
    Component: PaymentFailedPage,
    loader: protectedRouteLoader([UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.CUSTOMER]),
  },
];

export const appRouter = createBrowserRouter([
  {
    Component: GlobalLayout,
    children: mainRoutes,
  },
]);
