import React, { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Drawer } from 'shared/ui/Drawer';
import { useGetContractByIdQuery } from 'entities/Contract';
import { useGetInvoicesByContractIdQuery } from 'entities/Invoice';
import { NoResults } from 'shared/ui/NoResults';
import { getContractId } from '../model/selectors/getContractId';
import { contractDetailsDrawerActions } from '../model/slice/contractDetailDrawerSlice';
import { Billing } from './Billing';
import { ContractDetails } from './ContractDetails';
import { useOnContractPaymentsChanged } from 'entities/Payment';
import { useGetAssignedDepositPaymentQuery } from 'entities/Deposit';

export const ContractDetailsDrawer: FC = memo(() => {
  const dispatch = useAppDispatch();

  const contractId = useAppSelector(getContractId);

  const { data: contract } = useGetContractByIdQuery(contractId, { skip: !contractId });
  const { data: depositPayment } = useGetAssignedDepositPaymentQuery(contract?.deposit?.depositId, { skip: !contract?.deposit });

  const invoicesByContractIdParams = {
    contractId,
    includeContractItemsRelations: true,
  };
  const { data: invoices, refetch, isUninitialized } = useGetInvoicesByContractIdQuery(invoicesByContractIdParams, { skip: !contractId });

  const refetchInvoices = useCallback(() => {
    if (!isUninitialized) {
      void refetch();
    }
  }, [isUninitialized, refetch]);

  useOnContractPaymentsChanged(refetchInvoices);

  const closeContractDetails = useCallback((): void => {
    dispatch(contractDetailsDrawerActions.setOpenContractDetails(null));
  }, [dispatch]);

  return (
    <Drawer width={904} open={Boolean(contractId)} onClose={closeContractDetails}>
      {contract && invoices ? (
        <div className="min-h-full h-max desktop:flex">
          <div className="hidden py-4 px-11 desktop:block desktop:flex-1">
            <ContractDetails contract={contract} invoices={invoices} closeContractDetails={closeContractDetails} />
          </div>
          <div className="px-4 py-4 desktop:flex-1 desktop:shadow-left desktop:px-11">
            <Billing contract={contract} invoices={invoices} depositPayment={depositPayment} />
          </div>
        </div>
      ) : (
        <NoResults />
      )}
    </Drawer>
  );
});
