export interface ContractDetailDrawerSchema {
  contractId: Nullable<string>;
  paymentInvoiceId: Nullable<string>;
}

export enum DepositPaymentStatus {
  NOT_PAID = 'Not paid',
  PAID = 'Paid',
  CANCELLED = 'Cancelled',
  REFUNDED = 'Refunded',
}
