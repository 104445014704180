import { Currencies } from 'app/appState/model/types';
import { CreditNote } from 'entities/CreditNote';
import { DepositRefundNote } from 'entities/Deposit';
import { DocumentType, Invoice } from 'entities/Invoice';
import { User } from 'entities/User';

export enum PaymentProcessor {
  STRIPE = 'stripe',
  PAYPAL = 'payPal',
  BLUE_MEDIA = 'blueMedia',
  SEPA = 'sepa_debit',
  BANK_TRANSFER = 'bank_transfer',
  OTHER = 'other', // Duplicate of bank transfer
}

export enum PaymentMethod {
  STRIPE = 'stripe',
  STRIPE_AUTO = 'stripe_auto',
  PAYPAL = 'payPal',
  BLUE_MEDIA = 'blueMedia',
  SEPA = 'sepa_debit',
  CASH = 'cash',
  USER_BALANCE = 'user_balance',
  BANK_TRANSFER = 'bank_transfer',
  OTHER = 'other',
}

export enum PaymentStatus {
  COMPLETED = 'Completed',
  REFUND_IN_PROGRESS = 'Refund in progress',
  REFUNDED = 'Refunded',
  REFUND_ERROR = 'Refund error',
}

export interface Refund {
  refundId: string;
  paymentMethod: PaymentProcessor;
  transactionId: string;
  currency: string;
  amount: number;
  documentNumber: string;
  documentType: DocumentType;
  description?: string;
}

export interface Payment {
  paymentId: string;
  user: User;
  paymentMethod: PaymentProcessor;
  transactionId: string;
  currency: string;
  documentNumber: string;
  documentType: DocumentType;
  invoice: Invoice;
  amount: number;
  description?: string;
  creditNote?: CreditNote;
  refund?: Refund;
  depositRefundNote?: DepositRefundNote;
  paymentStatus: PaymentStatus;
  refundedBy: string;
  createdAt: string;
}

export interface PaymentParams {
  paymentId: string;
  description?: Nullable<string>;
}

export interface MarkAsRefundedPaymentParams extends PaymentParams {
  paymentMethod: string;
  amount: number;
  isDepositRefund?: boolean;
}

export interface CreditAndRefundPaymentParams extends MarkAsRefundedPaymentParams {
  currency?: Currencies;
}

export interface CreditPaymentParams extends PaymentParams {
  creditedAmount: number;
}

export interface PaymentResponse {
  redirectionUrl: string;
  paymentData?: CustomAny;
}
