import { api } from 'app/config/apiConfig';
import { USERS_PATH } from '../utils/constants';
import { SendFranchiseSectionEmailParams } from '../model/types';

export const sendFranchiseSectionEmailApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendFranchiseSectionEmail: builder.mutation<void, SendFranchiseSectionEmailParams>({
      query: (data) => ({
        url: `${USERS_PATH}/send-franchise-section`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useSendFranchiseSectionEmailMutation } = sendFranchiseSectionEmailApi;
