export const getBoxDiscountedPrice = ({
  warehouseDiscounts,
  boxPrice,
}: {
  warehouseDiscounts: Nullable<string[]> | undefined;
  boxPrice: Nullable<number> | undefined;
}): Nullable<number> => {
  const firstMonthDiscountValue = warehouseDiscounts?.[0].split(',')[0].split('-')[1]; // get discount value from array ["1-12%,2-12%", "1-10%,2-20%"]

  const isPercentageDiscount = firstMonthDiscountValue?.includes('%');

  const isFirstMonthDiscount = firstMonthDiscountValue?.includes('1-');

  if (firstMonthDiscountValue && boxPrice && isFirstMonthDiscount) {
    const discountValue = isPercentageDiscount
      ? Number(firstMonthDiscountValue.slice(0, firstMonthDiscountValue.indexOf('%')))
      : Number(firstMonthDiscountValue);

    return isPercentageDiscount ? boxPrice - (boxPrice * discountValue) / 100 : boxPrice - discountValue;
  }

  return null;
};
