import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { useCallback } from 'react';
import { uploadModalActions } from '../model/slice/uploadModalSlice';
import { getUploadModalState } from '../model/selectors/getUploadModalState';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';

interface HooApi {
  files: Nullable<UploadFile[]>;
  handleFileChange: (data: UploadChangeParam<UploadFile>) => void;
  resetFile: () => void;
}

export const useFileUpload = (): HooApi => {
  const dispatch = useAppDispatch();

  const { files } = useAppSelector(getUploadModalState);

  const handleFileChange = useCallback(
    (data: UploadChangeParam<UploadFile>): void => {
      const fileList = data.fileList.map((file) => ({ ...file }));

      dispatch(uploadModalActions.setFile(fileList));
    },
    [dispatch],
  );

  const resetFile = useCallback((): void => {
    dispatch(uploadModalActions.resetFile());
  }, [dispatch]);

  return { files, handleFileChange, resetFile };
};
