import { getAccessToken } from 'entities/User';
import { APP_LANGUAGE_KEY } from '../constants';
import { AppLanguage } from 'app/config/i18Config/types';

export const generateKnowledgeBaseUrl = (knowledgeBaseUrl: string): string => {
  const token = getAccessToken();

  const appLanguage = localStorage.getItem(APP_LANGUAGE_KEY) as AppLanguage;

  const language = appLanguage.split('_')[0];
  console.log('language', language);

  const url =
    language === 'de'
      ? `${knowledgeBaseUrl}/franchise/knowledge-base?token=${token}`
      : `${knowledgeBaseUrl}/${language}/franchise/knowledge-base?token=${token}`;

  return url;
};
