import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { ServerTable } from 'shared/ui/ServerTable';
import { ReactComponent as EditUserTableIcon } from 'shared/assets/icons/EditUserTableIcon.svg';
import { ReactComponent as EditIcon } from 'shared/assets/icons/EditIcon.svg';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { ReactComponent as ContractIcon } from 'shared/assets/icons/ContractWhiteIcon.svg';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { ReactComponent as DeleteIcon } from 'shared/assets/icons/DeleteIcon.svg';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ReactComponent as ContractsTableIcon } from 'shared/assets/icons/ContractsTableIcon.svg';
import { ReactComponent as CancelIcon } from 'shared/assets/icons/CancelIcon.svg';
import { ReactComponent as DocumentIcon } from 'shared/assets/icons/DocumentIcon.svg';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Lead, useDeleteLeadMutation, useGetPaginatedLeadsQuery, useSendClarificationMutation, useUpdateLeadMutation } from 'entities/Lead';
import dayjs from 'dayjs';
import { Toggle } from 'shared/ui/Toggle';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { LeadNoteDrawer, leadNoteDrawerActions } from 'features/LeadNoteDrawer';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { Paragraph } from 'shared/ui/Paragraph';
import { showNotification } from 'app/providers/NotificationsProvider';
import { sendOfferModalActions } from 'widgets/SendOfferModal';
import { AppLanguage } from 'app/config/i18Config/types';
import { stepperModalActions } from 'features/StepperModal';
import { ExportEntity, getLeadColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { TableFilterType, useTableFilterContext } from 'features/TableFilter';
import { BadgeColor, RowBadge } from 'shared/ui/ServerTable/RowBadge';
import { EmailHistoryModal, useEmailHistoryModal } from 'widgets/EmailHistoryModal';
import { LeadsListFilter } from './LeadsListFilter';
import { transformLeadsListFilters } from '../utils/helpers/transformLeadsListFilters';
import { LeadFormModal, leadFormModalActions, LeadFormModalMode } from 'features/LeadFormModal';
import { DrawerMode, noteDrawerActions } from 'features/NoteDrawer';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useNavigate } from 'react-router-dom';
import { RowSideBadge } from 'shared/ui/ServerTable/RowSideBadge';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';
import { UserPermissions } from 'entities/User';
import { useGlobalConfirmModal } from 'app/globalConfirmModal';

interface LeadsTableProps {
  externalFilters: TableFilterType;
}

export const LeadsTable: FC<LeadsTableProps> = memo((props) => {
  const { externalFilters } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useAppTranslation(['user', 'common']);

  const [deleteLead] = useDeleteLeadMutation();
  const [updateLead] = useUpdateLeadMutation();
  const [sendClarification] = useSendClarificationMutation();

  const isForbiddenDelete = useCheckPermission(UserPermissions.DELETE_UNITS);

  const { openExportModal } = useOpenExportModal();

  const { openModal, closeModal } = useGlobalConfirmModal();

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const { openEmailHistoryModal } = useEmailHistoryModal();

  useEffect(() => {
    // TO DO INVESTIGATE HOW TO CLEAR ALL FILTERS WITHOUT USE EFFECT !!!
    return () => {
      clearAllFilters();
    };
  }, []);

  const handleOpenLeadNoteDrawer = useCallback(
    (lead: Lead): void => {
      dispatch(leadNoteDrawerActions.setOpenLeadNoteDrawer(lead));
    },
    [dispatch],
  );

  const handleSendClarification = useCallback(
    async (email?: Nullable<string>): Promise<void> => {
      try {
        if (email) {
          await sendClarification(email).unwrap();

          closeModal();

          showNotification('info', t('Success', { ns: 'common' }), t('Clarification mail has been sent successfully'));
        }
      } catch (error) {
        showNotification('error', t('Error', { ns: 'common' }), t('Error when sending clarification mail'));
        console.log('error', error);
      }
    },
    [t, sendClarification, closeModal],
  );

  const openSendOfferModal = useCallback((): void => {
    dispatch(sendOfferModalActions.setOpened(true));
  }, [dispatch]);

  const handleSelectOfferModalLead = useCallback(
    (lead: Lead): void => {
      if (lead?.email) {
        dispatch(sendOfferModalActions.setSelectedEmail(lead.email));
        dispatch(sendOfferModalActions.setSelectedLanguage(lead.language as AppLanguage));
        dispatch(stepperModalActions.changeActiveStepIndex(1));
      }

      openSendOfferModal();
    },
    [dispatch, openSendOfferModal],
  );

  const createLead = useCallback((): void => {
    dispatch(leadFormModalActions.setOpenLeadFormModal({ mode: LeadFormModalMode.LEAD_CREATE }));
  }, [dispatch]);

  const editLead = useCallback(
    (lead: Lead): void => {
      dispatch(leadFormModalActions.setOpenLeadFormModal({ mode: LeadFormModalMode.LEAD_EDIT, lead }));
    },
    [dispatch],
  );

  const createNote = useCallback(
    (lead: Lead): void => {
      dispatch(noteDrawerActions.setOpenNoteDrawer({ mode: DrawerMode.NOTE_CREATE, lead }));
    },
    [dispatch],
  );

  const openClarificationConfirmModal = useCallback(
    (email?: Nullable<string>): void => {
      openModal({
        title: t('Send clarification mail'),
        description: t('Are you sure you want to send clarification mail?'),
        onCancel: closeModal,
        onOk: async () => {
          await handleSendClarification(email);
        },
        isConfirmDisabled: !email,
      });
    },
    [closeModal, handleSendClarification, openModal, t],
  );

  const handleDeleteLead = useCallback(
    async (selectedLead: Lead): Promise<void> => {
      try {
        await deleteLead(selectedLead.leadId).unwrap();
        showNotification('info', t('Success', { ns: 'common' }), t('Lead deleted successfully'));
      } catch (error) {
        showNotification('error', t('Error', { ns: 'common' }), t('Error when deleting lead'));
        console.log('error', error);
      } finally {
        closeModal();
      }
    },
    [deleteLead, t, closeModal],
  );

  const openLeadDeletionConfirmModal = useCallback(
    (selectedLead: Lead): void => {
      openModal({
        title: t('Delete lead'),
        description: t('Are you sure you want to delete this lead?'),
        onCancel: closeModal,
        onOk: async () => {
          await handleDeleteLead(selectedLead);
        },
        isConfirmDisabled: isForbiddenDelete,
      });
    },
    [closeModal, handleDeleteLead, isForbiddenDelete, openModal, t],
  );

  const leadRowActions: Array<ServerTableRowActions<Lead>> = useMemo(
    () => [
      {
        name: 'editLead',
        icon: <EditUserTableIcon />,
        theme: 'clear',
        description: t('Edit lead'),
        onClick: (selectedLead) => {
          editLead(selectedLead);
        },
      },
      {
        name: 'editLeadNote',
        icon: <EditIcon />,
        theme: 'clear',
        description: t('Edit note'),
        onClick: (selectedLead) => {
          handleOpenLeadNoteDrawer(selectedLead);
        },
      },
      // {
      //   name: 'leadProfile',
      //   icon: <ProfileIcon />,
      //   theme: 'clear',
      //   onClick: (selectedUser) => {
      //     console.log(selectedUser);
      //   },
      // },
      {
        name: 'deleteLead',
        icon: <DeleteIcon />,
        theme: 'clear',
        description: t('Delete lead'),
        onClick: (selectedLead: Lead) => {
          openLeadDeletionConfirmModal(selectedLead);
        },
      },
      {
        name: 'sendOffer',
        theme: 'clear',
        label: t('Send offer'),
        description: t('Send offer'),
        icon: <ContractIcon className="[&>path]:fill-primary" />,
        onClick: (selectedUser) => {
          handleSelectOfferModalLead(selectedUser);
        },
      },
      {
        name: 'sendСlarification',
        theme: 'clear',
        label: t('Send clarification'),
        description: t('Send clarification'),
        icon: <CancelIcon />,
        onClick: (selectedLead) => {
          openClarificationConfirmModal(selectedLead.email);
        },
      },
      {
        name: 'createNote',
        theme: 'clear',
        label: t('Create Note'),
        description: t('Create Note'),
        icon: <PlusIcon className="[&>path]:stroke-primary" />,
        onClick: createNote,
      },
      {
        name: 'emailHistory',
        theme: 'clear',
        label: t('Offer history'),
        description: t('Offer history'),
        icon: <DocumentIcon className="[&>path]:stroke-primary" />,
        onClick: (selectedLead) => {
          selectedLead.email && openEmailHistoryModal(selectedLead.email);
        },
      },
    ],
    [
      createNote,
      editLead,
      handleOpenLeadNoteDrawer,
      handleSelectOfferModalLead,
      openClarificationConfirmModal,
      openLeadDeletionConfirmModal,
      openEmailHistoryModal,
      t,
    ],
  );

  const handleToggleLeadLeft = useCallback(
    async (lead: Lead) => {
      try {
        await updateLead({ leadId: lead.leadId, isLeft: !lead.isLeft }).unwrap();
        showNotification('info', t('Success', { ns: 'common' }), t('Lead left changed successfully'));
      } catch (error) {
        showNotification('error', t('Error', { ns: 'common' }), t('Error when changing lead left'));
        console.log('error', error);
      }
    },
    [t, updateLead],
  );

  const goToUserContracts = useCallback(
    (lead: Lead): void => {
      navigate(`${AppRoutes.USERS}/${lead.userId}/contracts`);
    },
    [navigate],
  );

  const leadColumns = useMemo<ColumnsType<Lead>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        render: (_, record) => {
          const isNewLead = dayjs().diff(dayjs(record.createdAt), 'day') < 7 && !record?.contractId;

          let badgeColor: BadgeColor = 'success';

          if (record.isContractPaid && !record.isLeft) badgeColor = 'accent';
          if (record.contractId && !record.isLeft && !record.isContractPaid && !record.contractEndDate) badgeColor = 'warn';
          if (record.isLeft) badgeColor = 'primary';
          if (record.reservationId && !record.contractId && !record.isLeft && !record.isContractPaid && !record.contractEndDate)
            badgeColor = 'primaryLight';
          if (record.contractId && record.contractEndDate && !record.isLeft) badgeColor = 'error';

          let badgeTitle = t('Active requests');

          if (record.isContractPaid) badgeTitle = t('Paid');
          if (record.contractId && !record.isContractPaid) badgeTitle = t('Not paid');
          if (record.reservationId && !record.contractId) badgeTitle = t('Reservation');
          if (record.contractId && record.contractEndDate && !record.isLeft) badgeTitle = t('End');
          if (record.isLeft) badgeTitle = t('Left');

          return (
            <div>
              {record.firstName ? (
                <span className="text-primary font-semibold">
                  {record.firstName} {record.lastName || ''}
                </span>
              ) : (
                'N/A'
              )}
              {isNewLead && <sup className="text-sm text-success p-1">{t('New')}</sup>}
              <RowBadge color={badgeColor} title={badgeTitle} />
              {record.isAutoMailingEnabled && (
                <RowSideBadge color="success" toolTipColor="white" toolTipFontColor="primary" title={t('Automailing enabled')} />
              )}
            </div>
          );
        },
      },
      {
        title: t('Email/Phone'),
        key: 'email',
        render: (_, record) => (
          <div>
            {record.email && (
              <div className="flex gap-2 text-primary font-normal">
                {record.email}
                {record.userId && (
                  <ContractsTableIcon
                    className="cursor-pointer"
                    onClick={() => {
                      goToUserContracts(record);
                    }}
                  />
                )}
              </div>
            )}
            {record.phone && <div className="text-primary font-normal">{record.phone}</div>}
          </div>
        ),
      },
      {
        title: t('Warehouse'),
        key: 'warehouseId',
        render: (_, record) => (
          <>
            {record.warehouse && (
              <div className="text-primary">
                <div className="font-normal">{record.warehouse.name}</div>
                <div className="font-normal">{record.warehouse.address}</div>
              </div>
            )}
          </>
        ),
      },
      {
        title: t('Date'),
        key: 'createdAt',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => <div className=" text-primary font-normal">{getClientDateFormat(record.createdAt)}</div>,
      },
      {
        title: t('Box size'),
        key: 'square',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <>
            {record.square && (
              <div>
                {record.square}m<sup>2</sup>
              </div>
            )}
          </>
        ),
      },
      {
        title: t('Left'),
        key: 'isLeft',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <div>
            <Toggle
              checked={!record.isLeft}
              onChange={async () => {
                await handleToggleLeadLeft(record);
              }}
            />
            <div className="text-xs text-primaryLight mt-2">{t(!record.isLeft ? 'Relevant' : 'Irrelevant')}</div>
          </div>
        ),
      },
      {
        title: t('Note'),
        key: 'note',
        render: (_, record) => {
          const paragraphs = record.note?.split('\n');

          const paragraphElements = paragraphs?.map((paragraph: string, index: number) => (
            <p key={index} className="font-normal !mb-0">
              {paragraph}
            </p>
          ));

          return <Paragraph rows={3}>{paragraphElements}</Paragraph>;
        },
      },
    ],
    [goToUserContracts, handleToggleLeadLeft, t],
  );

  const leadTableActions: Array<ServerTableActions<Lead>> = useMemo(
    () => [
      {
        name: t('Send offer'),
        theme: 'primary',
        icon: <ContractIcon />,
        iconPosition: 'prev',
        onClick: openSendOfferModal,
      },
      {
        name: t('Create Lead'),
        theme: 'secondary',
        icon: <PlusIcon className="[&>path]:stroke-primary" />,
        iconPosition: 'prev',
        onClick: createLead,
      },
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: transformLeadsListFilters(appliedFilters),
            columns: getLeadColumnKeys(t),
            entity: ExportEntity.LEAD,
          });
        },
      },
      {
        name: t('Filters', { ns: 'common' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [appliedFilters, createLead, openExportModal, setFiltersOpened, openSendOfferModal, t],
  );

  return (
    <div className="space-y-4">
      <ServerTable
        columns={leadColumns}
        rowActions={leadRowActions}
        tableActions={leadTableActions}
        tableActionsPosition="prev"
        fetchData={useGetPaginatedLeadsQuery}
        defaultFilters={transformLeadsListFilters(appliedFilters)}
        rowKey="leadId"
        search
        searchPlaceholder={t('Enter lead`s name, phone or email')}
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        rowActionsLimit={3}
        defaultSearch={externalFilters.search?.value}
      />
      <LeadFormModal />
      <LeadNoteDrawer />
      <LeadsListFilter />
      <EmailHistoryModal />
    </div>
  );
});
