import React, { FC, memo, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { sendFranchiseSectionModalActions } from '../model/slice/sendFranchiseSectionModalSlice';
import { Modal } from 'shared/ui/Modal';
import { getSelectedEmail } from '../model/selectors/getSelectedEmail';
import { ReactComponent as CancelIcon } from 'shared/assets/icons/CloseIcon.svg';
import { Button } from 'shared/ui/Button';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Form } from 'antd';
import { Select } from 'shared/ui/Select';
import { AppLanguage } from 'app/config/i18Config/types';
import { Input } from 'shared/ui/Input';
import { getSelectedLanguage } from '../model/selectors/getSelectedLanguage';
import { showNotification } from 'app/providers/NotificationsProvider';
import { SendFranchiseSectionEmailParams, useSendFranchiseSectionEmailMutation } from 'entities/User';

export const SendFranchiseSectionModal: FC = memo(() => {
  const dispatch = useAppDispatch();

  const { t } = useAppTranslation('user');

  const [sendFranchiseSectionEmail, { isLoading }] = useSendFranchiseSectionEmailMutation();

  const [form] = Form.useForm();

  const email = useAppSelector(getSelectedEmail);
  const language = useAppSelector(getSelectedLanguage);

  useEffect(() => {
    if (email) {
      form.setFieldsValue({
        email,
        language,
        sections: [{ name: '', link: '' }],
      });
    } else {
      form.resetFields();
    }
  }, [email, form, language]);

  const closeModal = useCallback(() => {
    dispatch(sendFranchiseSectionModalActions.resetModalState());
  }, [dispatch]);

  const handleSendFranchiseSection = useCallback(
    async (data: SendFranchiseSectionEmailParams) => {
      try {
        const sections = data.sections?.filter((section) => section.name);

        await sendFranchiseSectionEmail({ ...data, sections }).unwrap();

        showNotification('info', t('Success'), t('Email has been successfully sent'));

        closeModal();
      } catch (error: CustomAny) {
        showNotification('error', t('Error'), t('Error when sending email'));
      }
    },
    [closeModal, sendFranchiseSectionEmail, t],
  );

  const languages = [
    { label: 'English', value: AppLanguage.en },
    { label: 'Deutsch', value: AppLanguage.de },
    { label: 'Polski', value: AppLanguage.pl },
  ];

  return (
    <Modal zIndex={10000} width={800} isOpen={Boolean(email)} onClose={closeModal}>
      <div className="font-semibold text-2xl mb-4">{t('Franchise section details')}</div>
      <Form form={form} layout="vertical" onFinish={handleSendFranchiseSection} className="flex flex-col gap-5">
        <div className="border border-secondaryAccent rounded-lg p-7">
          <div className="font-semibold text-xl mb-4">{t('Email details')}</div>
          <div className="flex gap-5">
            <Form.Item
              className="flex-1"
              label={t('Email')}
              name="email"
              rules={[
                { type: 'email', message: t('Please enter correct email!') },
                { required: true, message: t('Please enter email!') },
              ]}
            >
              <Input placeholder={t('Please, enter email')} bordered />
            </Form.Item>

            <Form.Item
              className="flex-1"
              name="language"
              label={t('Language')}
              rules={[{ required: true, message: t('Please select offer language!') }]}
            >
              <Select placeholder={t('Select offer language')} options={languages} bordered />
            </Form.Item>
          </div>
        </div>
        <div className="border border-secondaryAccent rounded-lg p-7">
          <div className="font-semibold text-xl mb-4">{t('Sections')}</div>
          <Form.List name="sections">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <div key={key} className="flex gap-5 mb-4">
                    <Form.Item name={[name, 'name']} className="flex-1">
                      <Input placeholder={t('Section name')} bordered />
                    </Form.Item>

                    <Form.Item name={[name, 'link']} className="flex-1">
                      <Input placeholder={t('Section link')} bordered />
                    </Form.Item>

                    <div
                      className="w-[44px] h-[44px] mt-1 rounded-full bg-secondary flex justify-center items-center cursor-pointer"
                      onClick={() => {
                        remove(name);
                      }}
                    >
                      <CancelIcon className="cursor-pointer [&>path]:stroke-primary" width={32} height={32} />
                    </div>
                  </div>
                ))}
                <Button
                  onClick={() => {
                    add();
                  }}
                >
                  {t('Add Section')}
                </Button>
              </>
            )}
          </Form.List>
        </div>
        <div className="flex self-end mt-8">
          <Button type="submit" isLoading={isLoading}>
            {t('Send email')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
});
