import { api } from 'app/config/apiConfig';
import { CreditPaymentParams } from '../model/types';
import { CreditNote } from 'entities/CreditNote';

export const creditPaymentApi = api.injectEndpoints({
  endpoints: (build) => ({
    creditPayment: build.mutation<CreditNote, CreditPaymentParams>({
      query: (data) => ({
        url: `/billing-documents/payments/${data.paymentId}/credit`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Invoices', 'Deposit Payment', 'My bookings'],
    }),
  }),
});

export const { useCreditPaymentMutation } = creditPaymentApi;
