import React, { FC, ReactElement, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';
import { WarningPing } from 'shared/ui/WarningPing';
import { showNotification } from 'app/providers/NotificationsProvider';
import { CreditAndRefundPaymentParams, Payment, PaymentMethod, useCreditAndRefundPaymentMutation } from 'entities/Payment';
import { Select } from 'shared/ui/Select';
import { Form } from 'antd';
import { InputNumber } from 'shared/ui/InputNumber';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalSettings } from 'app/appState';
import { WarnTag } from 'shared/ui/WarnTag';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';
import { UserPermissions } from 'entities/User';
import { Checkbox } from 'shared/ui/Checkbox';

interface CreditAndRefundPaymentProps {
  payment: Payment;
  action: ReactElement;
  showIsDepositPayment?: boolean;
  isClosedContract: boolean;
}

export const CreditAndRefundPayment: FC<CreditAndRefundPaymentProps> = memo((props) => {
  const { payment, action, showIsDepositPayment, isClosedContract } = props;

  const [isOpenedModal, setOpenedModal] = useState(false);

  const isForbidden = useCheckPermission(
    isClosedContract ? UserPermissions.REFUND_CREDITED_PAYMENTS : UserPermissions.REFUND_ACTIVE_PAYMENTS,
  );

  const [form] = Form.useForm();

  const { t } = useAppTranslation(['contracts', 'common']);

  const globalSettings = useAppSelector(getGlobalSettings);

  const currency = globalSettings?.currency;

  const [isDepositRefund, setIsDepositRefund] = useState<boolean>(false);

  const maxRefundAmount = useMemo(
    () => (payment.creditNote ? payment.creditNote.creditedAmount : payment.amount),
    [payment.amount, payment.creditNote],
  );

  const [creditAndRefundPayment, { isLoading }] = useCreditAndRefundPaymentMutation();

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  useEffect(() => {
    form.setFieldValue('amount', maxRefundAmount);
  }, [maxRefundAmount, form]);

  const handleMarkAsRefundedPayment = useCallback(
    async (data: CreditAndRefundPaymentParams): Promise<void> => {
      try {
        if (payment) {
          await creditAndRefundPayment({ ...data, paymentId: payment.paymentId, currency, isDepositRefund });
          showNotification('info', t('Success'), t('Payment has been successfully credited and refunded'));
        }
      } catch (error: CustomAny) {
        showNotification('error', t('Error', { ns: 'common' }), t('Error when refunded payment'));
      } finally {
        closeModal();
      }
    },
    [payment, creditAndRefundPayment, currency, isDepositRefund, t, closeModal],
  );

  const paymentMethodOptions = [
    {
      label: t('Stripe'),
      value: PaymentMethod.STRIPE,
    },
    {
      label: t('Bank transfer'),
      value: PaymentMethod.BANK_TRANSFER,
    },
    {
      label: t('Cash'),
      value: PaymentMethod.CASH,
    },
    {
      label: t('Other'),
      value: PaymentMethod.OTHER,
    },
  ];

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <Modal className="max-w-[400px]" isOpen={isOpenedModal} onClose={closeModal}>
        <Form form={form} layout="vertical" onFinish={handleMarkAsRefundedPayment} className="flex flex-col" disabled={isForbidden}>
          <div className="mb-2">
            <WarningPing type="warning" />
          </div>
          <div className="font-semibold text-xl mb-3">{t('Refund payment')}</div>

          {showIsDepositPayment && (
            <div className="mt-2 mb-6">
              <WarnTag textSize="medium" disableIcon>
                {t(
                  'This payment is related to deposit invoice, partial credit notes or partial refunds may cause deposit refund problems.',
                )}
              </WarnTag>
            </div>
          )}

          <div className="text-primaryLight font-normal">
            {t('Are you sure you want to refund this payment? You must select payment method!')}
          </div>

          <div className="my-5">
            <Form.Item
              label={t('Select payment method')}
              name="paymentMethod"
              rules={[{ required: true, message: `${t('Please, select payment method')}!` }]}
            >
              <Select options={paymentMethodOptions} placeholder={t('Select payment method')} bordered />
            </Form.Item>
            <Form.Item
              label={t('Enter payment amount')}
              name="amount"
              rules={[
                { required: true, message: `${t('Please, enter payment amount')}!` },
                () => ({
                  async validator(_, value) {
                    if (!value || value <= maxRefundAmount) {
                      await Promise.resolve();
                      return;
                    }
                    return await Promise.reject(new Error(`${t('Payment amount must be less or equal')} ${maxRefundAmount}!`));
                  },
                }),
              ]}
            >
              <InputNumber placeholder={t('Enter payment amount')} bordered />
            </Form.Item>

            {showIsDepositPayment && isClosedContract && (
              <div className="mb-5">
                <Checkbox name="isDepositRefund" checked={isDepositRefund} onChange={setIsDepositRefund}>
                  <span className="text-m">{t('Deposit refund')}</span>
                </Checkbox>
              </div>
            )}

            <Form.Item name="description">
              <TextArea placeholder={t('Add your description...')} rows={4} bordered />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-3">
            <Button theme="secondary" onClick={closeModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>

            <Button type="submit" isLoading={isLoading} isDisabled={isForbidden}>
              {t('Save', { ns: 'common' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
});
