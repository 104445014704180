import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmailHistoryModalSchema } from '../types';

const initialState: EmailHistoryModalSchema = {
  email: null,
};

const emailHistoryModalSlice = createSlice({
  name: 'emailHistoryModal',
  initialState,
  reducers: {
    openModal: (state: EmailHistoryModalSchema, action: PayloadAction<Nullable<string>>) => {
      state.email = action.payload;
    },
    closeModal: (state: EmailHistoryModalSchema) => {
      state.email = null;
    },
  },
});

export const { actions: emailHistoryModalActions, reducer: emailHistoryModalReducer } = emailHistoryModalSlice;
