import { Form } from 'antd';
import React, { FC, memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { UpdateUser } from 'features/UpdateUser';
import { PHONE_NUMBER_REGEX } from 'shared/utils/regex';
import { PhoneInput } from 'shared/ui/PhoneInput';
import { NewPasswordModal } from './NewPasswordModal';
import { Select } from 'shared/ui/Select';
import { UserAccountType, useRole, UserRoles } from 'entities/User';

interface AccountDetailsFormProps {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountType: UserAccountType;
  company?: Nullable<string>;
  balance: Nullable<number>;
  role: UserRoles;
}

export const AccountDetailsForm: FC<AccountDetailsFormProps> = memo((props) => {
  const { userId, firstName, lastName, email, phone, balance, accountType, company } = props;

  const { t } = useAppTranslation(['profile', 'common']);

  const [newPasswordModalOpened, setNewPasswordModalOpened] = useState<boolean>(false);

  const { isManagement, isPartner } = useRole();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      firstName,
      lastName,
      email,
      phone,
      balance,
      accountType,
      company,
    });
  }, [accountType, balance, company, email, firstName, form, lastName, phone]);

  const openNewPasswordModal = useCallback(() => {
    setNewPasswordModalOpened(true);
  }, []);

  const closeNewPasswordModal = useCallback(() => {
    setNewPasswordModalOpened(false);
  }, []);

  const submitButtons = (): ReactElement => {
    return isManagement ? (
      <UpdateUser
        userId={userId}
        formInstance={form}
        action={
          <Button containerClassName="basis-1/2" size="large">
            {t('Change account details')}
          </Button>
        }
      />
    ) : (
      <Button containerClassName="basis-1/2" size="large" onClick={openNewPasswordModal} isDisabled={isPartner}>
        {t('Reset Password')}
      </Button>
    );
  };

  return (
    <div className="mt-10">
      <div className="mb-7 font-semibold text-2xl">{t('Account details')}</div>
      <Form className="space-y-2" name="accountDetailsForms" form={form} layout="vertical" disabled={!isManagement}>
        <div className="flex space-x-7">
          <Form.Item
            label={t('Account type')}
            name="accountType"
            className="w-1/2"
            rules={[{ required: true, message: t('Please, select account type') }]}
          >
            <Select
              placeholder={t('Select account type')}
              bordered
              options={[
                { label: t('Personal'), value: UserAccountType.PERSONAL },
                { label: t('Business'), value: UserAccountType.BUSINESS },
              ]}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.accountType !== nextValues.accountType}>
            {({ getFieldValue }) => {
              const isBusinessAccountType = getFieldValue('accountType') === UserAccountType.BUSINESS;

              return isBusinessAccountType ? (
                <Form.Item
                  label={t('Company')}
                  className="w-1/2"
                  name="company"
                  rules={[
                    { required: true, message: t('Please enter company!') },
                    {
                      min: 2,
                      max: 100,
                      message: t('Company name must have 2 - 100 characters!'),
                    },
                  ]}
                >
                  <Input placeholder={t('Enter company')} bordered />
                </Form.Item>
              ) : (
                <div className="w-1/2" />
              );
            }}
          </Form.Item>
        </div>

        <div className="flex items-center space-x-7">
          <Form.Item
            className="w-full"
            name="firstName"
            label={t('Name')}
            rules={[{ required: true, message: t('Please enter your first name!') }]}
          >
            <Input bordered placeholder={t('Enter first name')} />
          </Form.Item>
          <Form.Item
            className="w-full"
            name="lastName"
            label={t('Last Name')}
            rules={[{ required: true, message: t('Please enter your last name!') }]}
          >
            <Input bordered placeholder={t('Enter last name')} />
          </Form.Item>
        </div>

        <div className="flex items-center space-x-7">
          <Form.Item
            className="w-full"
            name="email"
            label={t('Email')}
            rules={[
              { required: true, message: t('Please enter your email!') },
              { type: 'email', message: t('Please enter correct email!') },
            ]}
          >
            <Input bordered placeholder={t('Enter email')} />
          </Form.Item>
          <Form.Item
            className="w-full"
            name="phone"
            label={t('Phone')}
            rules={[
              { required: true, message: t('Please enter your phone!') },
              { pattern: PHONE_NUMBER_REGEX, message: t('Please provide a valid phone number. Example: +123456789012345') },
            ]}
          >
            <PhoneInput placeholder={t('Enter phone')} bordered initialValue={phone} isDisabled={!isManagement} />
          </Form.Item>
        </div>
        <div className="flex justify-end">{submitButtons()}</div>
      </Form>
      <NewPasswordModal userId={userId} isOpened={newPasswordModalOpened} onClose={closeNewPasswordModal} />
    </div>
  );
});
