import { ColumnsType } from 'antd/es/table';
import { useGetCurrencySymbol } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { checkUserDataComplete, useGetPaginatedUsersListingQuery, UserForListing, UserRoles, getUserName, User } from 'entities/User';
import { ExportEntity, ExportModal, getCustomerColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { useSendLoginLink } from 'features/SendLoginLink';
import { TableFilterContextProvider, TableFilterType, useTableFilterContext } from 'features/TableFilter';
import React, { FC, memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CompletedStepIcon } from 'shared/assets/icons/CompletedStepIcon.svg';
import { ReactComponent as ContractsTableIcon } from 'shared/assets/icons/ContractsTableIcon.svg';
import { ReactComponent as CustomersIcon } from 'shared/assets/icons/CustomersIcon.svg';
import { ReactComponent as EditUserTableIcon } from 'shared/assets/icons/EditUserTableIcon.svg';
import { ReactComponent as ContractIcon } from 'shared/assets/icons/ContractWhiteIcon.svg';
import { ReactComponent as EmailIcon } from 'shared/assets/icons/EmailIcon.svg';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { ReactComponent as LeadIcon } from 'shared/assets/icons/LeadIcon.svg';
import { ReactComponent as PartnerIcon } from 'shared/assets/icons/PartnerIcon.svg';
import { ReactComponent as ManagerIcon } from 'shared/assets/icons/ManagerIcon.svg';
import { ReactComponent as StaffIcon } from 'shared/assets/icons/StaffIcon.svg';
import { ReactComponent as NoteTableIcon } from 'shared/assets/icons/NoteTableIcon.svg';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { ReactComponent as SuperUserIcon } from 'shared/assets/icons/SuperUserIcon.svg';
import { Paragraph } from 'shared/ui/Paragraph';
import { ServerTable } from 'shared/ui/ServerTable';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { TabSwitcher } from 'shared/ui/TabSwitcher';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { CreateUserModal, createUserModalActions } from 'widgets/CreateUserModal';
import { SendOfferModal, sendOfferModalActions } from 'widgets/SendOfferModal';
import { CompleteRegistrationModal, completeRegistrationModalActions } from 'widgets/CompleteRegistrationModal';
import { BadgeColor, RowSideBadge } from 'shared/ui/ServerTable/RowSideBadge';
import { ReactComponent as DocumentIcon } from 'shared/assets/icons/DocumentIcon.svg';
import { EmailHistoryModal, useEmailHistoryModal } from 'widgets/EmailHistoryModal';
import { LeadsTable } from './LeadsTable';
import { AppLanguage } from 'app/config/i18Config/types';
import { stepperModalActions } from 'features/StepperModal';
import { NoteDrawer } from 'features/NoteDrawer';
import { useQueryString } from 'shared/utils/helpers/convertQueryString';
import { SendFranchiseSectionModal, sendFranchiseSectionModalActions } from 'widgets/SendFranchiseSectionModal';
import { RowBadge } from 'shared/ui/ServerTable/RowBadge';

export const UsersPage: FC = memo(() => {
  const { parsedQuery, decode } = useQueryString();

  const externalFilters = decode(parsedQuery) as TableFilterType;

  const [selectedTabKey, setSelectedTabKey] = useState(externalFilters.tab?.value || 'Customers');

  const { openExportModal } = useOpenExportModal();

  const navigate = useNavigate();
  const currencySymbol = useGetCurrencySymbol();

  const { appliedFilters } = useTableFilterContext();

  const { t } = useAppTranslation(['user', 'common']);

  const { executeSendingLoginLink } = useSendLoginLink();

  const { openEmailHistoryModal } = useEmailHistoryModal();

  const dispatch = useAppDispatch();

  const goToUserContracts = useCallback(
    (user: UserForListing): void => {
      navigate(`${AppRoutes.USERS}/${user.userId}/contracts`);
    },
    [navigate],
  );

  const goToUserProfile = useCallback(
    (user: UserForListing): void => {
      navigate(`${AppRoutes.PROFILE}/${user.userId}`);
    },
    [navigate],
  );

  const openCreateUserModal = useCallback((): void => {
    dispatch(createUserModalActions.setOpened(true));
  }, [dispatch]);

  const openCompleteRegistrationModal = useCallback(
    (user: UserForListing): void => {
      dispatch(completeRegistrationModalActions.setOpened(user.userId));
    },
    [dispatch],
  );

  const openSendOfferModal = useCallback((): void => {
    dispatch(sendOfferModalActions.setOpened(true));
  }, [dispatch]);

  const handleSelectOfferModalUser = useCallback(
    (user: User): void => {
      dispatch(sendOfferModalActions.setSelectedEmail(user.email));
      dispatch(sendOfferModalActions.setSelectedLanguage(user.language as AppLanguage));
      dispatch(stepperModalActions.changeActiveStepIndex(1));

      openSendOfferModal();
    },
    [dispatch, openSendOfferModal],
  );

  const opentSendFranchiseSectionModal = useCallback(
    (user: User): void => {
      dispatch(sendFranchiseSectionModalActions.setSelectedEmail(user.email));
      dispatch(sendFranchiseSectionModalActions.setSelectedLanguage(user.language as AppLanguage));
    },
    [dispatch],
  );

  const rowActions: Array<ServerTableRowActions<UserForListing>> = useMemo(
    () => [
      {
        name: 'addNote',
        icon: <NoteTableIcon />,
        theme: 'clear',
        description: t('Add note'),
        onClick: (selectedUser) => {
          console.log(selectedUser);
        },
      },
      {
        name: 'viewContracts',
        icon: <ContractsTableIcon />,
        theme: 'clear',
        description: t('View contracts'),
        onClick: goToUserContracts,
      },
      {
        name: 'editUser',
        icon: <EditUserTableIcon />,
        theme: 'clear',
        description: t('Edit user'),
        onClick: goToUserProfile,
      },
      {
        name: 'sendLoginLink',
        icon: <EmailIcon className="[&>path]:stroke-primary" />,
        theme: 'clear',
        label: t('Send login link'),
        description: t('Send login link'),
        onClick: (selectedUser) => {
          executeSendingLoginLink({ userId: selectedUser.userId });
        },
      },
      {
        name: 'completeRegistration',
        icon: <CompletedStepIcon className="[&>path]:fill-primary" />,
        theme: 'clear',
        label: t('Complete registration'),
        description: t('Complete registration'),
        isDisabled: (item) => checkUserDataComplete(item),
        onClick: openCompleteRegistrationModal,
      },
      {
        name: 'sendOffer',
        theme: 'clear',
        label: t('Send offer'),
        description: t('Send offer'),
        icon: <ContractIcon className="[&>path]:fill-primary" />,
        onClick: handleSelectOfferModalUser,
      },
      {
        name: 'emailHistory',
        theme: 'clear',
        label: t('Email history'),
        description: t('Email history'),
        icon: <DocumentIcon className="[&>path]:stroke-primary" />,
        onClick: (selectedUser) => {
          selectedUser.email && openEmailHistoryModal(selectedUser.email);
        },
      },
    ],
    [
      executeSendingLoginLink,
      goToUserContracts,
      goToUserProfile,
      handleSelectOfferModalUser,
      openCompleteRegistrationModal,
      openEmailHistoryModal,
      t,
    ],
  );

  const partnerRowActions: Array<ServerTableRowActions<UserForListing>> = useMemo(
    () => [
      {
        name: 'editUser',
        icon: <EditUserTableIcon />,
        theme: 'clear',
        description: t('Edit user'),
        onClick: goToUserProfile,
      },
      {
        name: 'sendLoginLink',
        icon: <EmailIcon className="[&>path]:stroke-primary" />,
        theme: 'clear',
        label: t('Send login link'),
        description: t('Send login link'),
        onClick: (selectedUser) => {
          executeSendingLoginLink({ userId: selectedUser.userId });
        },
      },
      {
        name: 'completeRegistration',
        icon: <CompletedStepIcon className="[&>path]:fill-primary" />,
        theme: 'clear',
        label: t('Complete registration'),
        description: t('Complete registration'),
        isDisabled: (item) => checkUserDataComplete(item),
        onClick: openCompleteRegistrationModal,
      },
      {
        name: 'sendFranchiseSection',
        theme: 'clear',
        label: t('Send franchise section'),
        description: t('Send franchise section'),
        icon: <ContractIcon className="[&>path]:fill-primary" />,
        onClick: opentSendFranchiseSectionModal,
      },
      {
        name: 'emailHistory',
        theme: 'clear',
        label: t('Email history'),
        description: t('Email history'),
        icon: <DocumentIcon className="[&>path]:stroke-primary" />,
        onClick: (selectedUser) => {
          selectedUser.email && openEmailHistoryModal(selectedUser.email);
        },
      },
    ],
    [executeSendingLoginLink, goToUserProfile, openCompleteRegistrationModal, openEmailHistoryModal, opentSendFranchiseSectionModal, t],
  );

  const customerColumns = useMemo<ColumnsType<UserForListing>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        width: '15%',
        render: (_, record) => {
          const isUserDataCompleted = checkUserDataComplete(record);

          return (
            <div className="text-primary font-semibold">
              {getUserName(record)}
              {!isUserDataCompleted && (
                <RowSideBadge color="error" toolTipColor="white" toolTipFontColor="primary" title={t('Uncompleted registration')} />
              )}
            </div>
          );
        },
      },
      { title: t('Email'), key: 'email', render: (_, record) => record.email },
      { title: t('Phone'), key: 'phone', render: (_, record) => record.phone },
      { title: t('Date'), key: 'createdAt', render: (_, record) => getClientDateFormat(record.createdAt) },
      { title: t('Account type'), key: 'accountType', render: (_, record) => t(record.accountType) },
      {
        title: t('Balance'),
        key: 'balance',
        render: (_, record) => (
          <div>
            {record.email && (
              <div className="text-success font-semibold">{record.balance ? `${record.balance} ${currencySymbol}` : ''}</div>
            )}
          </div>
        ),
      },
      {
        title: t('Debt'),
        key: 'contractDebt',
        render: (_, record) => (
          <div>
            {record.email && (
              <div className="text-error font-semibold">{record.contractDebt ? `${record.contractDebt} ${currencySymbol}` : ''}</div>
            )}
          </div>
        ),
      },
      {
        title: t('Note'),
        key: 'note',
        render: (_, record) => {
          const paragraphs = record.note?.split('\n');

          const paragraphElements = paragraphs?.map((paragraph: string, index: number) => (
            <p key={index} className="font-normal !mb-0">
              {paragraph}
            </p>
          ));

          return <Paragraph rows={3}>{paragraphElements}</Paragraph>;
        },
      },
    ],
    [currencySymbol, t],
  );

  const columns = useMemo<ColumnsType<UserForListing>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        width: '15%',
        render: (_, record) => {
          const isUserDataCompleted = checkUserDataComplete(record);

          return (
            <div className="text-primary font-semibold">
              {getUserName(record)}
              {!isUserDataCompleted && (
                <RowSideBadge color="error" toolTipColor="white" toolTipFontColor="primary" title={t('Uncompleted registration')} />
              )}
            </div>
          );
        },
      },
      { title: t('Email'), key: 'email', render: (_, record) => record.email },
      { title: t('Phone'), key: 'phone', render: (_, record) => record.phone },
      { title: t('Date'), key: 'createdAt', render: (_, record) => getClientDateFormat(record.createdAt) },
      {
        title: t('Warehouses'),
        key: 'warehousesNames',
        render: (_, record) => (
          <div className="font-normal text-primary">
            {record.warehousesNames?.map((name) => {
              return <div key={name}>{name}</div>;
            })}
          </div>
        ),
      },
    ],
    [t],
  );

  const partnerColumns = useMemo<ColumnsType<UserForListing>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        render: (_, record) => {
          const isNewPartner = !record.warehousesNames;

          let badgeTitle = t('Lead');

          let badgeColor: BadgeColor = 'success';

          if (record.warehousesNames?.length > 0) {
            badgeColor = 'warn';
            badgeTitle = t('Partner');
          }

          return (
            <div className="my-2">
              {getUserName(record)}
              {isNewPartner && <sup className="text-sm text-success p-1">{t('New')}</sup>}
              <RowBadge color={badgeColor} title={badgeTitle} />
            </div>
          );
        },
      },
      { title: t('Email'), key: 'email', render: (_, record) => record.email },
      { title: t('Phone'), key: 'phone', render: (_, record) => record.phone },
      { title: t('Date'), key: 'createdAt', render: (_, record) => getClientDateFormat(record.createdAt) },
      {
        title: t('Warehouses'),
        key: 'warehousesNames',
        render: (_, record) => (
          <div className="font-normal text-primary">
            {record.warehousesNames?.map((name) => {
              return <div key={name}>{name}</div>;
            })}
          </div>
        ),
      },
      {
        title: t('Note'),
        key: 'note',
        render: (_, record) => {
          const paragraphs = record.note?.split('\n');

          const paragraphElements = paragraphs?.map((paragraph: string, index: number) => (
            <p key={index} className="font-normal !mb-0">
              {paragraph}
            </p>
          ));

          return <Paragraph rows={3}>{paragraphElements}</Paragraph>;
        },
      },
    ],
    [t],
  );

  const tabs = useMemo(
    () => [
      { key: 'Customers', label: t('Customers'), icon: <CustomersIcon /> },
      { key: 'Managers', label: t('Managers'), icon: <ManagerIcon /> },
      { key: 'Superusers', label: t('Superusers'), icon: <SuperUserIcon /> },
      { key: 'Partners', label: t('Partners'), icon: <PartnerIcon /> },
      { key: 'Staff', label: t('Staff'), icon: <StaffIcon /> },
      { key: 'Leads', label: t('Leads'), icon: <LeadIcon /> },
    ],
    [t],
  );

  const tableActions: Array<ServerTableActions<UserForListing>> = useMemo(
    () => [
      {
        name: t('Add user'),
        icon: <PlusIcon />,
        iconPosition: 'prev',
        onClick: openCreateUserModal,
      },
    ],
    [openCreateUserModal, t],
  );

  const customerTableActions: Array<ServerTableActions<UserForListing>> = useMemo(
    () => [
      ...tableActions,
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: appliedFilters,
            columns: getCustomerColumnKeys(t),
            entity: ExportEntity.USER,
          });
        },
      },
    ],
    [appliedFilters, openExportModal, t, tableActions],
  );

  const renderTabContent = useCallback((): Nullable<ReactNode> => {
    switch (selectedTabKey) {
      case 'Customers':
        return (
          <ServerTable
            columns={customerColumns}
            rowActions={rowActions}
            fetchData={useGetPaginatedUsersListingQuery}
            defaultFilters={{ role: UserRoles.CUSTOMER, orderBy: 'createdAt:DESC' }}
            rowKey="userId"
            search
            tableActions={customerTableActions}
            tableActionsPosition="prev"
            rowActionsLimit={3}
          />
        );
      case 'Managers':
        return (
          <ServerTable
            columns={columns}
            rowActions={rowActions}
            fetchData={useGetPaginatedUsersListingQuery}
            defaultFilters={{ role: UserRoles.MANAGER }}
            tableActions={tableActions}
            tableActionsPosition="prev"
            rowKey="userId"
            search
            rowActionsLimit={3}
          />
        );
      case 'Superusers':
        return (
          <ServerTable
            columns={columns}
            rowActions={rowActions}
            fetchData={useGetPaginatedUsersListingQuery}
            defaultFilters={{ role: UserRoles.SUPERUSER }}
            tableActions={tableActions}
            tableActionsPosition="prev"
            rowKey="userId"
            search
            rowActionsLimit={3}
          />
        );
      case 'Partners':
        return (
          <ServerTable
            columns={partnerColumns}
            rowActions={partnerRowActions}
            fetchData={useGetPaginatedUsersListingQuery}
            defaultFilters={{ role: UserRoles.PARTNER }}
            rowKey="userId"
            search
            rowActionsLimit={1}
            tableActionsPosition="prev"
            tableActions={tableActions}
          />
        );
      case 'Staff':
        return (
          <ServerTable
            columns={columns}
            rowActions={rowActions}
            fetchData={useGetPaginatedUsersListingQuery}
            defaultFilters={{ role: UserRoles.STAFF }}
            rowKey="userId"
            search
            rowActionsLimit={3}
            tableActionsPosition="prev"
            tableActions={tableActions}
          />
        );
      case 'Leads':
        return (
          <TableFilterContextProvider>
            <LeadsTable externalFilters={externalFilters} />
          </TableFilterContextProvider>
        );
      default:
        return null;
    }
  }, [
    selectedTabKey,
    customerColumns,
    rowActions,
    customerTableActions,
    columns,
    tableActions,
    partnerColumns,
    partnerRowActions,
    externalFilters,
  ]);

  const switchTab = useCallback((tabKey: string): void => {
    setSelectedTabKey(tabKey);
  }, []);

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Users')}</div>
      <TabSwitcher tabs={tabs} selectedTabKey={selectedTabKey} onChangeTab={switchTab}>
        {renderTabContent()}
      </TabSwitcher>
      <ExportModal />
      <SendOfferModal />
      <CreateUserModal />
      <CompleteRegistrationModal />
      <NoteDrawer />
      <EmailHistoryModal />
      <SendFranchiseSectionModal />
    </div>
  );
});
