import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useGetRentOptionsQuery } from 'entities/RentOption';
import { useSpecialPromotionModal } from 'features/SpecialPromotion';
import { Button } from 'shared/ui/Button';
import { ReactComponent as CleverFoxIcon } from 'shared/assets/icons/CleverFoxIcon.svg';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';

export const CleverFoxPromotionContent: FC = memo(() => {
  const { t } = useAppTranslation('common');

  const navigate = useNavigate();

  const { data } = useGetRentOptionsQuery({ isSpecialPromotion: true });
  const { closeModal } = useSpecialPromotionModal();

  const { isDesktop } = useScreenBreakpoints();

  const goToBooking = useCallback(() => {
    navigate(data?.length ? `${AppRoutes.BOOKING}?rentOptionId=${data[0].rentOptionId}` : AppRoutes.BOOKING);
    closeModal();
  }, [closeModal, data, navigate]);

  return (
    <div className="!bg-[#FDFBF4] flex w-[380px] desktop:w-[1000px] my-15 rounded-xl p-6">
      {isDesktop && <CleverFoxIcon className="w-[372px] mb-7 flex-2" />}

      <div className="flex flex-col flex-1 items-center pt-10 gap-4 desktop:gap-16 desktop:py-10">
        <div className="flex flex-col">
          <div className="text-[28px] font-fugaz text-[#BA5631] text-center desktop:text-[56px]">{t('Sparfuchs-Special')}</div>
          <div className="text-[12px] text-[#363066] text-center desktop:text-[24px] font-bold">
            {t('Neues Jahr, mehr Platz für gute Vorsätze!')}
          </div>
        </div>

        {!isDesktop && <CleverFoxIcon className="w-[145px] h-[145px]" />}

        <div className="flex flex-col max-w-[515px]">
          <div className="text-[14px] font-fugaz text-[#D37B39] text-center desktop:text-[28px] font-semibold">
            {t('3 Monate mieten – nur 2 zahlen!')}
          </div>
          <div className="text-[12px] text-[#363066] text-center desktop:text-[24px] font-bold">{t('Nur für Neukunden')}</div>
          <div className="text-[12px] text-[#363066] text-center desktop:text-[24px] font-bold">
            {t('vom 10. Januar bis 10. Februar 2025!')}
          </div>
          <div className="text-[12px] text-[#363066] text-center desktop:text-[24px] font-normal">{t('Nicht verpassen!')}</div>

          <div className="flex flex-col mt-3 desktop:mt-8">
            <div className="text-[12px] text-[#363066] text-center desktop:text-[24px] font-normal">
              {t('Starten Sie organisiert ins neue Jahr und sichern Sie sich')}
            </div>
            <div className="text-[12px] text-[#363066] text-center desktop:text-[24px] font-bold">{t('extra Stauraum zum Sparpreis!')}</div>
          </div>
        </div>

        <Button
          containerClassName="min-w-[255px] max-w-[380px]"
          className="!bg-[#BA5631]"
          fontColor="text-white"
          fontSize={isDesktop ? 'large' : 'medium'}
          onClick={goToBooking}
        >
          {t('Jetzt Lagerraum sichern und sparen!')}
        </Button>
      </div>
    </div>
  );
});
