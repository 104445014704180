import { API_URL } from 'app/config/envVariables';
import { getAccessToken } from 'entities/User';

export const redirectToContractFileLink = (fileUrl: string): void => {
  const token = getAccessToken();

  const contractFileLink = `${API_URL}/${fileUrl}?token=${token}`;

  window.location.href = contractFileLink;
};
