import { api } from 'app/config/apiConfig';
import { DepositCharge, ChargeDepositParams } from '../model/types';

export const chargeDepositApi = api.injectEndpoints({
  endpoints: (build) => ({
    chargeDeposit: build.mutation<DepositCharge, ChargeDepositParams>({
      query: (data) => ({
        url: `/billing-documents/deposits/${data.depositId}/charge`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Contracts', 'Contract details'],
    }),
  }),
});

export const { useChargeDepositMutation } = chargeDepositApi;
