import React, { FC, memo, useCallback } from 'react';
import { useGetInternalEquipmentByWarehouseIdQuery } from 'entities/InternalEquipment';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { WarningPing } from 'shared/ui/WarningPing';
import { useGeolocation } from 'shared/utils/hooks/useGeolocation';
import { useOpenWarehouseDoorMutation } from 'entities/Warehouse';
import { showNotification } from 'app/providers/NotificationsProvider';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { getWarehouseId } from '../model/selectors/getWarehouseId';
import { warehouseStatisticCardActions } from '../model/slice/warehouseStatisticCardSlice';

export const WarehouseDoorsModal: FC = memo(() => {
  const dispatch = useAppDispatch();

  const { t } = useAppTranslation(['warehouses', 'common']);

  const coords = useGeolocation();

  const [openDoor] = useOpenWarehouseDoorMutation();

  const warehouseId = useAppSelector(getWarehouseId);

  const { data } = useGetInternalEquipmentByWarehouseIdQuery(warehouseId, { skip: !warehouseId });

  const closeDoorsModal = useCallback((): void => {
    dispatch(warehouseStatisticCardActions.setDoorsModalOpened(null));
  }, [dispatch]);

  const handleOpenDoor = useCallback(
    async (lockAddress: Nullable<string>): Promise<void> => {
      if (warehouseId && lockAddress) {
        try {
          await openDoor({ warehouseId, args: { lockAddress, longitude: coords?.lng, latitude: coords?.lat } }).unwrap();
          showNotification('info', t('Success'), t('The door has been successfully opened', { ns: 'common' }));
        } catch (error: CustomAny) {
          console.log(error);
          showNotification('error', t('Error'), t('Error when opening door', { ns: 'common' }));
        }
      }
    },
    [coords, openDoor, t, warehouseId],
  );

  return (
    <Modal className="max-w-[343px]" isOpen={Boolean(warehouseId)} onClose={closeDoorsModal}>
      <WarningPing className="mb-4" />
      <div className="font-semibold text-lg mb-2">{t('Choose the door you need')}</div>
      <div className="text-primaryLight font-normal mb-7">{t('Click the button to access')}</div>
      <div className="space-y-3">
        {data?.map(({ name, internalEquipmentId, lockAddress }) => (
          <Button
            key={internalEquipmentId}
            onClick={() => {
              void handleOpenDoor(lockAddress);
            }}
          >
            {getLocalizedString(name)}
          </Button>
        ))}
        <Button theme="secondary" onClick={closeDoorsModal}>
          {t('Cancel')}
        </Button>
      </div>
    </Modal>
  );
});
