import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { GetProp, Image, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ReactComponent as UploadIcon } from 'shared/assets/icons/UploadIcon.svg';
import classNames from 'classnames';
import { MimeTypes } from 'features/UploadModal';

import s from './index.module.scss';
import { UploadProps } from 'antd/lib';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';

const { Dragger: DraggerComponent } = Upload;

interface DraggerProps {
  file?: Nullable<UploadFile | UploadFile[]>;
  title?: Nullable<string>;
  description?: Nullable<string>;
  multiple?: boolean;
  onChange?: (data: UploadChangeParam<UploadFile>) => void;
  maxCount?: number;
  allowedFormats?: MimeTypes[];
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const Dragger: FC<DraggerProps> = memo((props) => {
  const { file, title, description, onChange, allowedFormats, maxCount = 1, multiple = false } = props;

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<Nullable<string>>(null);

  const { t } = useAppTranslation('common');

  const { isDesktop } = useScreenBreakpoints();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange = (data: UploadChangeParam<UploadFile>): void => {
    setFileList(data.fileList);
    onChange?.(data);
  };

  const accept = useMemo(() => {
    return allowedFormats?.join(',');
  }, [allowedFormats]);

  useEffect(() => {
    if (!file) {
      setFileList([]);
    }
  }, [file]);

  const getBase64 = async (file: FileType): Promise<string> =>
    await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });

  const handlePreview = async (file: UploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  return (
    <>
      <DraggerComponent
        multiple={multiple}
        accept={accept}
        fileList={fileList}
        onChange={handleChange}
        maxCount={maxCount}
        className={classNames(s.dragger)}
        listType={isDesktop ? 'picture-card' : 'picture'}
        onPreview={handlePreview}
      >
        <div className="flex flex-col items-center">
          <UploadIcon />
          <div className="flex flex-col gap-2 my-4">
            {fileList.length === maxCount ? (
              <div className="text-warn font-normal">
                {t('You have uploaded the maximum number of files! To upload a new file, you need to remove one from the list')}
              </div>
            ) : (
              <div className="text-primaryLight font-normal">{title || t('Select a file or drag and drop here')}</div>
            )}

            <div className="font-normal text-xs text-primaryLight">{description}</div>
          </div>
        </div>
      </DraggerComponent>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => {
              setPreviewOpen(visible);
            },
            afterOpenChange: (visible) => {
              !visible && setPreviewImage(null);
            },
          }}
          src={previewImage}
        />
      )}
    </>
  );
});
