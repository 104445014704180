import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UploadModalPayload, UploadModalSchema } from '../types';
import { UploadFile } from 'antd';

const initialState: UploadModalSchema = {
  isOpened: false,
  title: null,
  description: null,
  files: null,
  submitArgs: null,
  allowedFormats: [],
  multiple: false,
  maxCount: null,
  draggerDescription: null,
};

const uploadModalSlice = createSlice({
  name: 'uploadModal',
  initialState,
  reducers: {
    openModal: (state: UploadModalSchema, action: PayloadAction<UploadModalPayload>) => {
      state.isOpened = true;
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.submitArgs = action.payload.submitArgs;
      state.allowedFormats = action.payload.allowedFormats;
      state.multiple = action.payload.multiple;
      state.maxCount = action.payload.maxCount;
      state.draggerDescription = action.payload.draggerDescription;
    },
    closeModal: (state: UploadModalSchema) => {
      state.isOpened = false;
      state.files = null;
      state.title = null;
      state.description = null;
      state.submitArgs = null;
      state.allowedFormats = [];
      state.multiple = false;
      state.maxCount = null;
      state.draggerDescription = null;
    },
    setFile: (state: UploadModalSchema, action: PayloadAction<UploadFile[]>) => {
      state.files = action.payload;
    },
    resetFile: (state) => {
      state.files = null;
    },
  },
});

export const { actions: uploadModalActions, reducer: uploadModalReducer } = uploadModalSlice;
