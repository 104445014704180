import { Dayjs } from 'dayjs';
import { CachedBox } from 'entities/Box';
import { Insurance } from 'entities/Insurance';
import { Invoice, InvoiceFrequencyType } from 'entities/Invoice';
import { User, UserAccountType } from 'entities/User';
import { VatRate } from 'entities/VatRate';
import { Warehouse } from 'entities/Warehouse';
import { Currencies } from 'app/appState/model/types';
import { Deposit } from 'entities/Deposit';
import { RentOptionDocument } from 'entities/RentOption';

export type PaymentFrequencyPeriod = 'regular' | 'entire period';

export enum UnitType {
  BOX = 'Box',
  INSURANCE = 'Insurance',
  PARKING_PLACE = 'ParkingPlace',
  POSTAL_BOX = 'PostalBox',
  RENTING_STUFF = 'RentingStuff',
  DEPOSIT = 'Deposit',
}

export enum AutochargeStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  STOPPED = 'stopped',
}

export type ContractUnitType = UnitType | 'Deposit';

export interface ContractPeriodsUnitPrice {
  periodNumber: number;
  rate: number;
  discountAmount: number;
  discountPercent: number;
  initialRate: number;
}

export interface Contract {
  contractId: string;
  contractNumber: number;
  warehouse: Warehouse;
  user: User;
  startDate: Dayjs;
  initialEndDate: Dayjs;
  endDate: Dayjs;
  isPrePaymentApplied: boolean;
  noticeLeaveDate: Dayjs;
  noticeGivenDate: Dayjs;
  leaveDate: Dayjs;
  chargedTo: string;
  contractItems?: ContractItem[];
  invoiceFrequencyType: InvoiceFrequencyType;
  invoiceFrequency: number;
  isContractSigned: boolean;
  contractSignId: Nullable<string>;
  invoiceLeadDays: number;
  notes: Nullable<string>;
  accessDenied: boolean;
  autocharges: AutochargeStatus;
  currency: string;
  createdAt: string;
  updatedAt: string;
  invoices?: Invoice[];
  deposit?: Deposit;
  description?: string;
}

export interface ContractWithBookingInfo {
  contractId: string;
  contractNumber: string;
  startDate: string;
  initialEndDate: string;
  endDate: string;
  isPrePaymentApplied: boolean;
  chargedTo: string;
  invoiceFrequencyType: InvoiceFrequencyType;
  invoiceFrequency: number;
  isContractSigned: boolean;
  accessDenied: boolean;
  autocharges: AutochargeStatus;
  currency: string;
  isContractPaid: boolean;
  contractDebt: number;
  paidDays: number;
  nextInvoiceDueDate?: string;
  nextInvoiceAmount?: string;
}

export interface ContractItem {
  contractItemId: string;
  contractUnitType: UnitType;
  box: Nullable<CachedBox>;
  insurance: Nullable<Insurance>;
  vatRate: VatRate;
  isOneTimePayment: boolean;
  oneTimePaymentAmount: number;
  isStandalone: boolean;
  description: Nullable<string>;
}

export interface ContractOptionsSchema {
  contractOptions: ContractInfo;
}

export interface ContractInfo {
  accountType?: UserAccountType;
  contractDuration: number;
  invoiceFrequencyType: InvoiceFrequencyType;
  startDate?: Nullable<Dayjs>;
  endDate?: Nullable<Dayjs>;
  insuranceId: Nullable<string>;
  insuranceAmountWithVat: number;
  insuranceAmountWithoutVat: number;
  insuranceVatRatePercent: number;
  insuranceVatRateAmount: number;
  insuranceRate: number;
  coverageAmount: Nullable<number>;
  depositAmount: number;
  payForEntirePeriod: boolean;
  totalToPay: number;
  balanceToChargeAmount: number;
  totalToCharge: number;
  contractPeriodsUnitPrices: ContractPeriodsUnitPrice[];
  vatRatePercent: number;
  vatRateAmount: number;
  discountPercent: number;
  discountAmount: number;
  rentOption?: Nullable<RentOptionDocument>;
}

export interface GenerateContractParams {
  warehouseName: string;
  warehouseAddress: string;
  startDate: string;
  boxId: string;
  boxNumber: string;
  boxSquare: number;
  invoiceFrequencyType: InvoiceFrequencyType;
  contractDuration: number;
  clientFirstName: string;
  clientLastName: string;
  clientStreet: string;
  clientCountry: string;
  clientCity: string;
  clientHouse: string;
  clientApartment?: string;
  clientZip: string;
  language: string;
  clientEmail: string;
  clientPhone: string;
  rentalCost: string;
  discountPercent: number;
  discountAmount: number;
  insuranceAmount: number;
  vatRateAmount: number;
  totalToPay: number;
  paymentFrequencyPeriod: PaymentFrequencyPeriod;
  currency: Currencies;
  insuranceVatRateAmount: number;
}

export interface CreateContractParams {
  userId: string;
  warehouseId: string;
  insuranceId?: string;
  rentOptionId: string;
  startDate: string;
  endDate?: string;
  invoiceFrequencyType: InvoiceFrequencyType;
  invoiceFrequency: number;
  paymentFrequencyPeriod: PaymentFrequencyPeriod;
  useUserBalance: boolean;
}

export interface UpdateContractParams {
  noticeLeaveDate?: string;
  noticeGivenDate?: string;
  leaveDate?: string;
  chargedTo?: string;
  isContractSigned?: boolean;
  accessDenied?: boolean;
  contractSignId?: string;
  notes?: string;
}

export interface CloseContractParams {
  issueUnpaidInvoices: boolean;
  enableDepositCharge: boolean;
  enableUserBalanceCharge: boolean;
  ignoreMinContractDuration: boolean;
  refundOptions: 'default' | 'refund-all' | 'no-refunds';
  leaveDate: string;
}

export interface NotifyLeaveDateParams {
  contractId: string;
  noticeLeaveDate: string;
}

export interface NotifyLeaveDaysLimits {
  [InvoiceFrequencyType.DAY]: number;
  [InvoiceFrequencyType.WEEK]: number;
  [InvoiceFrequencyType.MONTH]: number;
}
