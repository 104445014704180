import { Divider, Dropdown, MenuProps } from 'antd';
import React, { FC, memo, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { RequestModalMode, requestFormModalActions } from 'widgets/RequestModal';
import { getGlobalSettings, useGetCurrencySymbol } from 'app/appState';
import { LogOutButton } from './LogOutButton';
import { ReactComponent as SelectArrow } from 'shared/assets/icons/SelectArrow.svg';
import { ReactComponent as UserIcon } from 'shared/assets/icons/UserIcon.svg';
import { getLoggedUserData, useRole } from 'entities/User';
import { generateKnowledgeBaseUrl } from 'shared/utils/helpers/generateKnowledgeBaseUrl';

interface UserMenuProps {
  userEmail: string | undefined;
}

export const UserMenu: FC<UserMenuProps> = memo((props) => {
  const { userEmail } = props;

  const { t } = useAppTranslation(['header', 'booking']);

  const { isDesktop } = useScreenBreakpoints();

  const loggedUser = useAppSelector(getLoggedUserData);

  const currencySymbol = useGetCurrencySymbol();

  const { isManagement, isStaff, isPartner } = useRole();

  const globalSettings = useAppSelector(getGlobalSettings);

  const dispatch = useAppDispatch();

  const openRequestModal = (): void => {
    dispatch(
      requestFormModalActions.openRequestModal({
        title: t('Let`s get to know each other better!', { ns: 'booking' }),
        description: t('Please provide us with your information so that we can suggest the best pricing options that meet your needs.', {
          ns: 'booking',
        }),
        mode: RequestModalMode.CONTACT_US,
      }),
    );
  };

  let items: MenuProps['items'] = [
    {
      key: '0',
      label: <div className="mb-5 text-primaryLight">{userEmail}</div>,
    },
    {
      key: '1',
      label: <Link to={`${AppRoutes.PROFILE}/${loggedUser?.userId || ''}`}>{t('Profile')}</Link>,
    },
    {
      key: '2',
      label: <Link to={AppRoutes.MY_BOXES}>{t('My boxes')}</Link>,
    },
    {
      key: '3',
      label: <div onClick={openRequestModal}>{t('Contact us')}</div>,
    },
  ];

  if (isDesktop) {
    items.shift();
  }

  if (isManagement) {
    const adminAdditionalRoute = {
      key: '4',
      label: <Link to={AppRoutes.ADMIN_PANEL}>{t('Admin panel')}</Link>,
    };

    items.push(adminAdditionalRoute);
  }

  if (isPartner && globalSettings?.partnerKnowledgeBaseUrl) {
    const adminAdditionalRoute = {
      key: '8',
      label: <Link to={generateKnowledgeBaseUrl(globalSettings.partnerKnowledgeBaseUrl)}>{t('Knowledge base')}</Link>,
      className: '!text-warn animate-pulse',
    };

    items.push(adminAdditionalRoute);
  }

  if (!isDesktop && !isManagement) {
    const customerAdditionalRoute = {
      key: '5',
      label: <Link to={AppRoutes.BOOKING}>{t('Booking')}</Link>,
    };

    items.push(customerAdditionalRoute);
  }

  if (!isDesktop && !isManagement && isStaff) {
    const staffAdditionalRoutes = [
      {
        key: '6',
        label: <Link to={AppRoutes.WAREHOUSES}>{t('Warehouses')}</Link>,
      },
      {
        key: '7',
        label: <Link to={AppRoutes.BOXES}>{t('Boxes')}</Link>,
      },
    ];

    items = [...items, ...staffAdditionalRoutes];
  }

  const managementView = (): ReactElement => {
    return (
      <>
        <UserIcon className="[&>path]:stroke-accent" />
        <SelectArrow className="[&>path]:stroke-accent" />
      </>
    );
  };

  const customerView = (): ReactElement => {
    return (
      <>
        <UserIcon className="[&>path]:stroke-accent desktop:hidden" />
        <div className="hidden desktop:block">{userEmail}</div>
        <SelectArrow className="[&>path]:stroke-accent" />
      </>
    );
  };

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      placement="bottom"
      dropdownRender={(menu) => (
        <div className="px-3 py-5 shadow-xl rounded-lg bg-white">
          <div className="px-4 pb-4 text-accent">
            {t('Balance: {{balance}}', { balance: loggedUser?.balance ?? 0 })} {currencySymbol}
          </div>
          {React.cloneElement(menu as ReactElement, { style: { boxShadow: 'none', background: 'none' } })}
          <Divider />
          <LogOutButton />
        </div>
      )}
    >
      <div className="flex items-center text-accent cursor-pointer space-x-2">{isManagement ? managementView() : customerView()}</div>
    </Dropdown>
  );
});
