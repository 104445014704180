import { useCallback, useEffect, useState } from 'react';
import { useGlobalConfirmModal } from 'app/globalConfirmModal';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { CachedBox, useOpenBoxMutation } from 'entities/Box';
import { useCountDown } from 'shared/utils/hooks/useCountDown';
import { useGeolocation } from 'shared/utils/hooks/useGeolocation';

type BoxParams = Partial<Pick<CachedBox, 'boxId' | 'name' | 'timeToOpenLockMS' | 'lockAddress'>>;

interface OpenConfirmModalArgs {
  box: BoxParams;
  openByAdmin?: boolean;
  isExternalBox: boolean;
}

interface HooApi {
  executeBoxOpening: (args: OpenConfirmModalArgs) => void;
}

export const useOpenBox = (): HooApi => {
  const { t } = useAppTranslation(['contracts', 'common']);

  const { openModal, setModalLoading, closeModal } = useGlobalConfirmModal();

  const [isExternal, setExternal] = useState(false); // duplicated variable for use in useEffect

  const [openBox, { isLoading: isOpenLoading, isSuccess }] = useOpenBoxMutation();

  const coords = useGeolocation();

  const { isCountDown, isExpired, runCountDown } = useCountDown();

  useEffect(() => {
    const watchLoadingAndCountDown = (): void => {
      const isExternalBoxOpening = isOpenLoading || isCountDown;

      const isLoading = isExternal ? isExternalBoxOpening : isOpenLoading;

      const needCloseModal = isExternal ? isExpired : isSuccess;

      if (isLoading) {
        setModalLoading(isLoading);
      }

      if (needCloseModal) {
        setModalLoading(false);
        closeModal();
      }
    };

    watchLoadingAndCountDown();
  }, [closeModal, isCountDown, isExpired, isExternal, isOpenLoading, isSuccess, openModal, setModalLoading]);

  const handleOpenBox = useCallback(
    async ({ box, isExternalBox }: OpenConfirmModalArgs) => {
      try {
        if (box.lockAddress && box.boxId) {
          const params = {
            boxId: box.boxId,
            longitude: coords?.lng,
            latitude: coords?.lat,
          };

          await openBox(params).unwrap();

          isExternalBox && runCountDown(box?.timeToOpenLockMS ? box.timeToOpenLockMS / 1000 : 0);
        } else {
          showNotification('error', t('Error', { ns: 'common' }), t('Box does not have lock address', { ns: 'common' }));
        }
      } catch (error: CustomAny) {
        console.log(error);
        const errorMessage = t(error?.data?.message?.[0] || 'Error while opening box', { ns: 'common' });

        showNotification('error', t('Error', { ns: 'common' }), errorMessage);

        setModalLoading(false);
      }
    },
    [coords, openBox, runCountDown, t, setModalLoading],
  );

  const openConfirmModal = useCallback(
    ({ box, openByAdmin, isExternalBox }: OpenConfirmModalArgs): void => {
      setExternal(isExternalBox);

      openModal({
        title: t('Open box', { ns: 'common' }),
        description: t(isExternalBox ? 'Press confirm to open/close box {{boxName}}' : 'Are you sure you want to open box {{boxName}}', {
          boxName: box.name,
          ns: 'common',
        }),
        onOk: async () => {
          await handleOpenBox({ box, openByAdmin, isExternalBox });
        },
        onCancel: closeModal,
      });
    },
    [closeModal, handleOpenBox, openModal, t],
  );

  return {
    executeBoxOpening: openConfirmModal,
  };
};
