import React, { ComponentType, FC, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { BlackFridayPromotionContent } from 'widgets/BlackFridayPromotion';
import { ChristmasPromotionContent } from 'widgets/ChristmasPromotion';
import { CleverFoxPromotionContent } from 'widgets/CleverFoxPromotion';
import { NewYearPromotionContent } from 'widgets/NewYearPromotion';
import { PromotionEventTheme } from '../model/types';
import { useSpecialPromotionModal } from '../utils/hooks/useSpecialPromotionModal';
import BlackFridayOpener from 'shared/assets/images/BlackFridayOpener.png';
import ChristmasOpener from 'shared/assets/images/ChristmasOpener.png';
import NewYearOpener from 'shared/assets/images/NewYearOpener.png';

interface EventThemeParams {
  background: string;
  label: string;
  labelFont: string;
  Content: ComponentType;
  image: string;
  date: string;
  dateColor: string;
}

interface SpecialPromotionOpenerProps {
  eventTheme?: PromotionEventTheme;
}

export const SpecialPromotionOpener: FC<SpecialPromotionOpenerProps> = memo((props) => {
  const { eventTheme = 'default' } = props;

  const { t } = useAppTranslation('common');

  const { openModal, showSpecialPromotion } = useSpecialPromotionModal();

  const eventThemeMap: Record<PromotionEventTheme, EventThemeParams> = {
    default: {
      background: 'bg-gradient-to-r from-[636363] to-[#252425]',
      label: 'Black Friday Sale',
      labelFont: 'font-sans',
      Content: BlackFridayPromotionContent,
      date: '11.11',
      image: BlackFridayOpener,
      dateColor: 'text-primary',
    },
    blackFriday: {
      background: 'bg-gradient-to-r from-[636363] to-[#252425]',
      label: 'Black Friday Sale',
      labelFont: 'font-sans',
      Content: BlackFridayPromotionContent,
      date: '11.11',
      image: BlackFridayOpener,
      dateColor: 'text-primary',
    },
    christmas: {
      background: 'bg-gradient-to-r from-[#E31D1D] to-[#C11B2A]',
      label: 'Christmas Sale',
      labelFont: 'font-concert',
      Content: ChristmasPromotionContent,
      date: '25.12',
      image: ChristmasOpener,
      dateColor: 'text-white',
    },
    newYear: {
      background: 'bg-gradient-to-r from-[#F2C94C] to-[#F2994A]',
      label: 'Silvester-Kracher',
      labelFont: 'font-agbalumo',
      Content: NewYearPromotionContent,
      date: '27.12 - 02.01',
      image: NewYearOpener,
      dateColor: 'text-white',
    },
    cleverFox: {
      background: 'bg-gradient-to-r from-[#F2962A] to-[#FA7B34]',
      label: 'Sparfuchs-Special',
      labelFont: 'font-fugaz',
      Content: CleverFoxPromotionContent,
      date: '10.01 - 10.02',
      image: NewYearOpener,
      dateColor: 'text-white',
    },
  };

  const openSpecialPromotionModal = (): void => {
    openModal(eventThemeMap[eventTheme].Content);
  };

  if (!showSpecialPromotion) {
    return null;
  }

  return (
    <div
      className={`absolute z-50 left-0 right-0 top-0 text-center text-white cursor-pointer ${eventThemeMap[eventTheme].background}`}
      onClick={openSpecialPromotionModal}
    >
      <div className="relative flex justify-between items-center px-7 desktop:h-14 h-[82px]">
        <div className={`text-2xl ${eventThemeMap[eventTheme].labelFont} uppercase z-[60]`}>{t(eventThemeMap[eventTheme].label)}</div>
        <div className="font-normal text-lg z-[90]">{t('We have discounts for you. Click to find out')}</div>
        <div className={`z-[10] text-2xl ${eventThemeMap[eventTheme].labelFont} ${eventThemeMap[eventTheme].dateColor}`}>
          {eventThemeMap[eventTheme].date}
        </div>
        <img className="absolute right-0 h-full" src={eventThemeMap[eventTheme].image} alt="Promotion" />
      </div>
    </div>
  );
});
