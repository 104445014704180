import { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { AutochargeStatus, Contract, redirectToContractFileLink, useGetPaginatedContractsQuery } from 'entities/Contract';
import { ReactComponent as EditPencilIcon } from 'shared/assets/icons/EditPencilIcon.svg';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { WarnTag } from 'shared/ui/WarnTag';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { ServerTable } from 'shared/ui/ServerTable';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { contractDetailsDrawerActions } from 'widgets/ContractDetailsDrawer';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { createContractModalActions } from 'widgets/CreateContractModal';
import { ExportEntity, getContractColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { useTableFilterContext } from 'features/TableFilter';
import { ReactComponent as EditIcon } from 'shared/assets/icons/EditIcon.svg';
import { contractPageActions } from '../model/slice/contractsPageSlice';
import { ContractNoteDrawer } from './ContractNoteDrawer';
import { ContractsListFilter } from './ContractsListFilter';
import { transformContractsListFilters } from '../utils/transformContractsListFilters';
import { NotifyLeaveDate } from 'features/NotifyLeaveDate';
import { getUserName, useRole } from 'entities/User';
import { RowSideBadge } from 'shared/ui/ServerTable/RowSideBadge';
import { useQueryString } from 'shared/utils/helpers/convertQueryString';

export const ContractsTable: FC = memo(() => {
  const navigate = useNavigate();

  const { parsedQuery } = useQueryString();

  const { t } = useAppTranslation(['contracts', 'common']);
  const dispatch = useAppDispatch();

  const { isPartner } = useRole();

  const { openExportModal } = useOpenExportModal();

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  useEffect(() => {
    if (parsedQuery.fileUrl) {
      redirectToContractFileLink(parsedQuery.fileUrl);
    }
  }, [parsedQuery.fileUrl]);

  const openContractDetails = useCallback(
    (contract: Contract): void => {
      dispatch(contractDetailsDrawerActions.setOpenContractDetails(contract.contractId));
    },
    [dispatch],
  );

  const openContractNotes = useCallback(
    (contract: Contract): void => {
      dispatch(contractPageActions.setOpenContractNoteDrawer(contract));
    },
    [dispatch],
  );

  const goToUserContracts = useCallback(
    (userId: string): void => {
      if (userId) {
        navigate(`${AppRoutes.USERS}/${userId}/contracts`);
      }
    },
    [navigate],
  );

  const rowActions: Array<ServerTableRowActions<Contract>> = useMemo(
    () => [
      {
        name: 'editNote',
        icon: <EditIcon />,
        theme: 'clear',
        description: t('View/edit notes'),
        onClick: openContractNotes,
      },
      {
        name: 'contractInfo',
        label: t('Details'),
        description: t('Details'),
        onClick: openContractDetails,
      },
    ],
    [openContractDetails, openContractNotes, t],
  );

  const openCreateContractModal = useCallback((): void => {
    dispatch(createContractModalActions.setOpened(true));
  }, [dispatch]);

  const tableActions: Array<ServerTableActions<Contract>> = useMemo(
    () => [
      {
        name: t('Create Contract'),
        icon: <PlusIcon />,
        iconPosition: 'prev',
        onClick: openCreateContractModal,
        disabled: isPartner,
      },
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: transformContractsListFilters(appliedFilters),
            columns: getContractColumnKeys(t),
            entity: ExportEntity.CONTRACT,
          });
        },
      },
      {
        name: t('Filters', { ns: 'common' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [appliedFilters, isPartner, openCreateContractModal, openExportModal, setFiltersOpened, t],
  );

  const columns = useMemo<ColumnsType<Contract>>(
    () => [
      {
        title: t('Warehouse'),
        key: 'warehouse',
        width: '10%',
        render: (_, record) => (
          <div>
            <Link className="text-accent underline font-normal" to={`${AppRoutes.WAREHOUSES}/${record.warehouse.warehouseId}`}>
              {record.warehouse.name}
            </Link>
            {[AutochargeStatus.ENABLED, AutochargeStatus.STOPPED].includes(record.autocharges) && (
              <RowSideBadge
                color={record.autocharges === AutochargeStatus.ENABLED ? 'success' : 'warn'}
                toolTipColor="white"
                toolTipFontColor="primary"
                title={t('Autocharges {{status}}', { status: t(record.autocharges) })}
              />
            )}
          </div>
        ),
      },
      {
        title: t('Contract number'),
        key: 'contractNumber',
        width: '5%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <div
            className="text-accent underline font-normal cursor-pointer"
            onClick={() => {
              openContractDetails(record);
            }}
          >
            #{record.contractNumber}
          </div>
        ),
      },
      {
        title: t('Customer'),
        key: 'customerEmail',
        render: (_, record) => (
          <div
            onClick={() => {
              goToUserContracts(record.user.userId);
            }}
            className="flex flex-col"
          >
            <div className="text-sm text-accent cursor-pointer underline"> {record.user.email}</div>
            <div className="font-bold text-accent cursor-pointer underline">{getUserName(record.user)}</div>
          </div>
        ),
      },
      {
        title: t('Area'),
        key: 'area',
        width: '10%',
        render: (_, record) => {
          const boxItem = record.contractItems?.find((item) => Boolean(item.box))?.box || null;

          return (
            <>
              <div>{boxItem?.square} m²</div>
              <div className="font-normal text-sm">{boxItem?.name}</div>
            </>
          );
        },
      },
      {
        title: (
          <>
            <div>{t('Date of creation')}</div>
            <div>{t('Arrival Date')}</div>
          </>
        ),
        key: 'createdAt',
        width: '10%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <>
            <div className="font-medium">{getClientDateFormat(record.createdAt)}</div>
            <div className="text-sm font-normal">{getClientDateFormat(record.startDate)}</div>
          </>
        ),
      },
      {
        title: t('Planned Departure Date'),
        key: 'noticeLeaveDate',
        width: '7%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <div className="flex space-x-7">
            <div className="font-normal">{getClientDateFormat(record.noticeLeaveDate)}</div>

            {!record.leaveDate && (
              <NotifyLeaveDate
                contractId={record.contractId}
                invoiceFrequencyType={record.invoiceFrequencyType}
                initialEndDate={record.initialEndDate}
                noticeLeaveDate={record.noticeLeaveDate}
                action={<EditPencilIcon className="cursor-pointer" />}
              />
            )}
          </div>
        ),
      },
      {
        title: t('Closing Date'),
        key: 'leaveDate',
        width: '6%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => getClientDateFormat(record.leaveDate),
      },
      { title: t('Paid Days'), key: 'paidDays' }, // TODO получить последнюю оплату по договору, взять у неё конечную дату (date to). От неё отнять сегодняшнюю дату
      {
        title: (
          <>
            <div>{t('Accrued for payment')}</div>
            <div>{t('Paid')}</div>
          </>
        ),
        key: 'accruedForPaymentPaid', // TODO нет на бэке
        width: '10%',
      },
    ],
    [goToUserContracts, openContractDetails, t],
  );

  return (
    <div className="space-y-4">
      <WarnTag className="w-max" textSize="medium">
        {t('Attention! The contract can be closed only after the client takes their things from the box.')}
      </WarnTag>
      <ServerTable
        fetchData={useGetPaginatedContractsQuery}
        columns={columns}
        rowKey="contractId"
        title={t('Contracts')}
        tableActions={tableActions}
        tableActionsPosition="prev"
        rowActions={rowActions}
        search
        defaultFilters={transformContractsListFilters(appliedFilters)}
        searchPlaceholder={t('Enter customer or contract number')}
        tags={tags}
        onAllFiltersClear={clearAllFilters}
      />
      <ContractsListFilter />
      <ContractNoteDrawer />
    </div>
  );
});
