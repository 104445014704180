import { api } from 'app/config/apiConfig';
import { MarkAsRefundedPaymentParams, Payment } from '../model/types';

export const markAsRefundedPaymentApi = api.injectEndpoints({
  endpoints: (build) => ({
    markAsRefundedPayment: build.mutation<Payment, MarkAsRefundedPaymentParams>({
      query: (data) => ({
        url: `/billing-documents/payments/${data.paymentId}/mark-refund`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Invoices', 'Deposit Payment', 'My bookings'],
    }),
  }),
});

export const { useMarkAsRefundedPaymentMutation } = markAsRefundedPaymentApi;
