import { useCallback, useState } from 'react';
import { GeolocationCoords } from 'app/types/common';
import { useGeolocation } from 'shared/utils/hooks/useGeolocation';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';

interface UseMapRouteReturnType {
  directionResult: Nullable<google.maps.DirectionsResult>;
  calculateRoute: (destination: GeolocationCoords) => Promise<void>;
  resetRoute: () => void;
}

export const useMapRoute = (): UseMapRouteReturnType => {
  const coords = useGeolocation();

  const { t } = useAppTranslation('common');

  const [directionResult, setDirectionResult] = useState<Nullable<google.maps.DirectionsResult>>(null);

  const resetRoute = useCallback((): void => {
    setDirectionResult(null);
  }, []);

  const calculateRoute = useCallback(
    async (destination: GeolocationCoords): Promise<void> => {
      resetRoute();

      try {
        const origin = coords;

        if (origin) {
          const directionService = new google.maps.DirectionsService();

          const result = await directionService.route({
            origin,
            destination,
            travelMode: google.maps.TravelMode.DRIVING,
          });

          setDirectionResult(result);
        }
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when calculating route'));
      }
    },
    [coords, resetRoute, t],
  );

  return { directionResult, calculateRoute, resetRoute };
};
