import { api } from 'app/config/apiConfig';

export const markDepositAsRefundedApi = api.injectEndpoints({
  endpoints: (build) => ({
    markDepositAsRefunded: build.mutation<void, string>({
      query: (depositId) => ({
        url: `/billing-documents/deposits/${depositId}/mark-refund`,
        method: 'POST',
      }),
      invalidatesTags: ['Contracts', 'Contract details'],
    }),
  }),
});

export const { useMarkDepositAsRefundedMutation } = markDepositAsRefundedApi;
