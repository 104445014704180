import { useEffect, useState } from 'react';
import { GeolocationCoords } from 'app/types/common';

export const useGeolocation = (): Nullable<GeolocationCoords> => {
  const [coordinates, setCoordinates] = useState<Nullable<GeolocationCoords>>(null);

  useEffect(() => {
    navigator.geolocation?.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      setCoordinates({ lat: latitude, lng: longitude });
    });
  }, []);

  return coordinates;
};
