import { Form } from 'antd';
import dayjs from 'dayjs';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppLanguage } from 'app/config/i18Config/types';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { CompleteRegisterUserParams, useGetUserByIdQuery, User, UserAccountType } from 'entities/User';
import { useCompleteRegistration } from 'features/CompleteRegistration';
import { Button } from 'shared/ui/Button';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { DatePicker } from 'shared/ui/DatePicker';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';
import { PhoneInput } from 'shared/ui/PhoneInput';
import { Select } from 'shared/ui/Select';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { PHONE_NUMBER_REGEX } from 'shared/utils/regex';
import { datePickerValueUnder18, disableFutureDatesUnder18 } from 'shared/utils/helpers/disable18YearsDatePicker';
import { getOpenedUserId } from '../model/selectors/getOpenedUserId';
import { completeRegistrationModalActions } from '../model/slice/completeRegistrationModalSlice';

interface CompleteRegistrationModalProps {
  userData?: User;
}

export const CompleteRegistrationModal: FC<CompleteRegistrationModalProps> = memo(() => {
  const { t } = useAppTranslation('user');
  const dispatch = useAppDispatch();

  const userId = useAppSelector(getOpenedUserId);

  const [form] = Form.useForm();

  const { data } = useGetUserByIdQuery({ userId });

  useEffect(() => {
    if (data) {
      const {
        language,
        identificationNumber,
        zip,
        email,
        city,
        phone,
        country,
        street,
        accountType,
        house,
        apartment,
        firstName,
        lastName,
        dateOfBirth,
        company,
      } = data;

      form.setFieldsValue({
        language,
        identificationNumber,
        zip,
        email,
        city,
        phone,
        country,
        street,
        accountType,
        house,
        apartment,
        firstName,
        lastName,
        dateOfBirth: dateOfBirth ? dayjs(dateOfBirth) : undefined,
        company,
      });
    }
  }, [data, form]);

  const { completeRegistration, closeConfirmModal, openConfirmModal, isOpenedConfirm, isLoading } = useCompleteRegistration();

  const closeModal = useCallback(() => {
    dispatch(completeRegistrationModalActions.resetModalState());
  }, [dispatch]);

  const onConfirm = useCallback(async () => {
    if (userId) {
      const userData: CompleteRegisterUserParams = form.getFieldsValue();

      completeRegistration({ userId, userData, onSuccess: closeModal });
    }
  }, [closeModal, completeRegistration, form, userId]);

  const languages = [
    { label: 'English', value: AppLanguage.en },
    { label: 'Deutsch', value: AppLanguage.de },
    { label: 'Polski', value: AppLanguage.pl },
  ];

  return (
    <Modal width={1100} isOpen={Boolean(userId)} onClose={closeModal}>
      <Form className="flex space-x-20" name="completeRegistration" layout="vertical" form={form} onFinish={openConfirmModal}>
        <div className="basis-1/2">
          <div className="font-semibold text-2xl mb-4">{t('Account details')}</div>
          <Form.Item label={t('Name')} name="firstName" rules={[{ required: true, message: t('Please, enter name') }]}>
            <Input placeholder={t('Enter name')} bordered />
          </Form.Item>
          <Form.Item label={t('Last name')} name="lastName" rules={[{ required: true, message: t('Please, enter last name') }]}>
            <Input placeholder={t('Enter last name')} bordered />
          </Form.Item>
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[
              { required: true, message: t('Please, enter email') },
              { type: 'email', message: t('Please enter correct email') },
            ]}
          >
            <Input placeholder={t('Enter email')} bordered />
          </Form.Item>
          <Form.Item
            label={t('Phone')}
            name="phone"
            rules={[
              { pattern: PHONE_NUMBER_REGEX, message: t('Please provide a valid phone number') },
              { required: true, message: `${t('Please, enter phone number')}!` },
            ]}
          >
            <PhoneInput placeholder={t('Enter phone number')} bordered initialValue={data?.phone} />
          </Form.Item>
          <Form.Item label={t('Language')} name="language" rules={[{ required: true, message: t('Please, select language') }]}>
            <Select placeholder={t('Select language')} bordered options={languages} />
          </Form.Item>
          <Form.Item label={t('Identification number')} name="identificationNumber">
            <Input placeholder={t('Enter identification number')} bordered />
          </Form.Item>
          <Form.Item
            className="w-full"
            name="dateOfBirth"
            label={t('Date of birth')}
            rules={[
              {
                required: true,
                message: t('Please enter date of birth!'),
              },
            ]}
          >
            <DatePicker
              className="h-[52px] w-full"
              format={CLIENT_DATE_FORMAT}
              placeholder={t('Date of birth')}
              inputReadOnly
              disabledDate={disableFutureDatesUnder18}
              defaultPickerValue={datePickerValueUnder18}
            />
          </Form.Item>
          <Form.Item label={t('Account type')} name="accountType" rules={[{ required: true, message: t('Please, select account type') }]}>
            <Select
              placeholder={t('Select account type')}
              bordered
              options={[
                { label: t('Personal'), value: UserAccountType.PERSONAL },
                { label: t('Business'), value: UserAccountType.BUSINESS },
              ]}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.accountType !== nextValues.accountType}>
            {({ getFieldValue }) => {
              const isBusinessAccountType = getFieldValue('accountType') === UserAccountType.BUSINESS;

              return isBusinessAccountType ? (
                <Form.Item label={t('Company')} name="company">
                  <Input placeholder={t('Enter company')} bordered />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
        </div>
        <div className="basis-1/2">
          <div className="font-semibold text-2xl mb-4">{t('Address')}</div>
          <Form.Item label={t('Country')} name="country" rules={[{ required: true, message: t('Please, enter country') }]}>
            <Input placeholder={t('Enter country')} bordered />
          </Form.Item>
          <div className="flex space-x-3 [&>*]:basis-1/2">
            <Form.Item label={t('City')} name="city" rules={[{ required: true, message: t('Please, enter city') }]}>
              <Input placeholder={t('Enter city')} bordered />
            </Form.Item>
            <Form.Item label={t('Zip code')} name="zip" rules={[{ required: true, message: t('Please, enter zip code') }]}>
              <Input placeholder={t('Enter zip code')} bordered />
            </Form.Item>
          </div>
          <Form.Item label={t('Street')} name="street" rules={[{ required: true, message: t('Please, enter street') }]}>
            <Input placeholder={t('Enter street')} bordered />
          </Form.Item>
          <div className="flex space-x-3 [&>*]:basis-1/2">
            <Form.Item label={t('House')} name="house" rules={[{ required: true, message: t('Please, enter house') }]}>
              <Input placeholder={t('Enter house')} bordered />
            </Form.Item>
            <Form.Item label={t('Apartment')} name="apartment">
              <Input placeholder={t('Enter apartment')} bordered />
            </Form.Item>
          </div>
          <Button type="submit">{t('Update user')}</Button>
        </div>
        <ConfirmModal
          isOpened={isOpenedConfirm}
          title={t('Update user')}
          description={t('Are you sure you want to update user?')}
          isLoading={isLoading}
          onOk={onConfirm}
          onCancel={closeConfirmModal}
        />
      </Form>
    </Modal>
  );
});
