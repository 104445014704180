import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useGetRentOptionsQuery } from 'entities/RentOption';
import { useSpecialPromotionModal } from 'features/SpecialPromotion';
import { Button } from 'shared/ui/Button';
import { ReactComponent as NewYearPromotionIcon } from 'shared/assets/icons/NewYearPromotionIcon.svg';
import { ReactComponent as NewYearDiscount1 } from 'shared/assets/icons/NewYearDiscount1.svg';
import { ReactComponent as NewYearDiscount2 } from 'shared/assets/icons/NewYearDiscount2.svg';
import { ReactComponent as NewYearDiscount3 } from 'shared/assets/icons/NewYearDiscount3.svg';
import { ReactComponent as StarEmoji } from 'shared/assets/icons/StarEmoji.svg';
import { ReactComponent as CalendarEmoji } from 'shared/assets/icons/CalendarEmoji.svg';
import { ReactComponent as ClockEmoji } from 'shared/assets/icons/ClockEmoji.svg';
import { ReactComponent as ChristmasTreeEmoji } from 'shared/assets/icons/ChristmasTreeEmoji.svg';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';

export const NewYearPromotionContent: FC = memo(() => {
  const { t } = useAppTranslation('common');

  const navigate = useNavigate();

  const { data } = useGetRentOptionsQuery({ isSpecialPromotion: true });
  const { closeModal } = useSpecialPromotionModal();

  const { isDesktop } = useScreenBreakpoints();

  const goToBooking = useCallback(() => {
    navigate(data?.length ? `${AppRoutes.BOOKING}?rentOptionId=${data[0].rentOptionId}` : AppRoutes.BOOKING);
    closeModal();
  }, [closeModal, data, navigate]);

  return (
    <div className="">
      <div className="bg-gray-100/90 flex flex-col items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[380px] desktop:w-[1200px] my-15 rounded-xl">
        <NewYearPromotionIcon className="mb-3.5 w-[239px] desktop:mb-7 desktop:w-[392px] p-4" />
        <div className="text-[22px] font-agbalumo text-[#C11B2A] text-center mb-7 desktop:text-[40px]">
          {t('Silvester-Kracher: 3 Wünsche für mehr Freiraum im neuen Jahr!')}
        </div>
        <div className="flex flex-wrap justify-center mb-7 desktop:space-x-36 desktop:flex-nowrap">
          <NewYearDiscount1 className="w-[100px] h-[130px] desktop:w-auto desktop:h-auto" />
          <NewYearDiscount2 className="w-[100px] h-[130px] desktop:w-auto desktop:h-auto" />
          <NewYearDiscount3 className="w-[100px] h-[130px] desktop:w-auto desktop:h-auto" />
        </div>
        <div className="bg-[#FFD9A1] p-2.5 mb-7 rounded-xl">
          <div className="flex flex-col items-center p-4 border-2 border-[#DB2031] rounded-xl [&>div]:text-[#DB2031] [&>div]:font-semibold [&>div]:text-sm desktop:text-lg">
            <div className="mb-4 text-center">
              {t('Wie im Märchen erfüllen wir Ihnen 3 Wünsche – starten Sie 2025 mit mehr Lebensraum!')}
            </div>
            <div className="flex items-center mb-2.5">
              <StarEmoji className="mr-2.5 shrink-0" />
              {t('Monat: 75% Rabatt')}
            </div>
            <div className="flex items-center mb-2.5">
              <StarEmoji className="mr-2.5 shrink-0" />
              {t('Monat: 50% Rabatt')}
            </div>
            <div className="flex items-center mb-2.5">
              <StarEmoji className="mr-2.5 shrink-0" />
              {t('Monat: 25% Rabatt')}
            </div>
            <div className="flex items-center mb-2.5">
              <CalendarEmoji className="mr-2.5 shrink-0" />
              {t('Nur vom 27. Dezember bis 2. Januar!')}
            </div>
            <div className="flex items-center mb-4">
              <ClockEmoji className="mr-2.5 shrink-0" />
              {t('Mindestmietdauer: 1 Monat.')}
            </div>
            <div className="flex items-center text-center font-agbalumo !text-lg !font-normal !desktop:text-xl">
              <ChristmasTreeEmoji className="mr-2.5 shrink-0" />
              {t('Sichern Sie sich jetzt Ihre Lagerbox und schaffen Sie Platz für Neues in 2025!')}
            </div>
          </div>
        </div>
        <Button
          containerClassName="min-w-[155px] max-w-[351px] mb-4"
          className="!bg-[#C11B2A]"
          fontColor="text-[#FFBC59]"
          fontSize={isDesktop ? 'medium' : 'small'}
          onClick={goToBooking}
        >
          {t('Jetzt buchen und sparen!')}
        </Button>
      </div>
    </div>
  );
});
