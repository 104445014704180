import { api } from 'app/config/apiConfig';

export const contractClosureRequestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    requestContractClosure: builder.mutation<void, { contractId: string; formData: FormData }>({
      query: (data) => ({
        url: `/contracts/${data.contractId}/closure-request`,
        body: data.formData,
        method: 'POST',
      }),
      invalidatesTags: ['Contracts'],
    }),
  }),
});

export const { useRequestContractClosureMutation } = contractClosureRequestApi;
