import { api } from 'app/config/apiConfig';
import { Payment } from 'entities/Payment';

export const getAssignedDepositPaymentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAssignedDepositPayment: build.query<Payment, string | void>({
      query: (depositId) => ({
        url: `/billing-documents/deposits/${depositId}/payment`,
      }),
      providesTags: ['Deposit Payment'],
    }),
  }),
});

export const { useGetAssignedDepositPaymentQuery } = getAssignedDepositPaymentApi;
