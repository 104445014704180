import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getEmail } from '../../model/selectors/getEmail';
import { emailHistoryModalActions } from '../../model/slice/emailHistoryModalSlice';

interface HookApi {
  isOpened: boolean;
  email: Nullable<string>;
  openEmailHistoryModal: (email: string) => void;
  closeEmailHistoryModal: () => void;
}

export const useEmailHistoryModal = (): HookApi => {
  const dispatch = useAppDispatch();

  const email = useAppSelector(getEmail);

  const openEmailHistoryModal = useCallback(
    (email: string) => {
      dispatch(emailHistoryModalActions.openModal(email));
    },
    [dispatch],
  );

  const closeEmailHistoryModal = useCallback(() => {
    dispatch(emailHistoryModalActions.closeModal());
  }, [dispatch]);

  return {
    isOpened: Boolean(email),
    email,
    openEmailHistoryModal,
    closeEmailHistoryModal,
  };
};
