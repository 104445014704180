import { api } from 'app/config/apiConfig';
import { Note, CreateNoteData } from '../model/types';

export const createNoteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createNote: builder.mutation<Note, CreateNoteData>({
      query: (data) => ({
        url: '/notes',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Notes', 'Leads'],
    }),
  }),
});

export const { useCreateNoteMutation } = createNoteApi;
