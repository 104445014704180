import React, { FC, ReactElement, memo, useCallback, useState } from 'react';
import { Form } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';
import { WarningPing } from 'shared/ui/WarningPing';
import { showNotification } from 'app/providers/NotificationsProvider';
import { InputNumber } from 'shared/ui/InputNumber';
import { UserPermissions } from 'entities/User';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';
import { useChargeDepositMutation } from 'entities/Deposit/api/chargeDepositApi';
import { ChargeDepositParams } from 'entities/Deposit';

interface ChargeDepositProps {
  depositId: string;
  amount: number;
  action: ReactElement;
}

export const ChargeDeposit: FC<ChargeDepositProps> = memo((props) => {
  const { depositId, action, amount } = props;

  const [isOpenedModal, setOpenedModal] = useState(false);

  const isForbidden = useCheckPermission(UserPermissions.CHARGE_DEPOSIT);

  const [form] = Form.useForm();

  const { t } = useAppTranslation('contracts');

  const [chargeDeposit, { isLoading }] = useChargeDepositMutation();

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  const handleChargeDeposit = useCallback(
    async (data: ChargeDepositParams): Promise<void> => {
      try {
        if (depositId) {
          await chargeDeposit({ ...data, depositId }).unwrap();
          showNotification('info', t('Success'), t('Deposit has been successfully charged'));
        }
      } catch (error: CustomAny) {
        showNotification('error', t('Error'), t('Error when charging deposit'));
      } finally {
        closeModal();
      }
    },
    [depositId, chargeDeposit, t, closeModal],
  );

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <Modal className="max-w-[400px]" isOpen={isOpenedModal} onClose={closeModal}>
        <Form form={form} layout="vertical" onFinish={handleChargeDeposit} className="flex flex-col" disabled={isForbidden}>
          <div className="mb-2">
            <WarningPing type="warning" />
          </div>
          <div className="font-semibold text-xl mb-3">{t('Charge deposit')}</div>

          <div className="text-primaryLight font-normal">{t('Are you sure you want to charge this deposit?')}</div>

          <div className="my-5">
            <Form.Item
              label={t('Enter amount to charge')}
              name="amount"
              rules={[
                { required: true, message: `${t('Please, enter amount')}!` },
                () => ({
                  async validator(_, value) {
                    if (!value || value <= amount) {
                      await Promise.resolve();
                      return;
                    }
                    return await Promise.reject(new Error(`${t('Amount must be less or equal')} ${amount}!`));
                  },
                }),
              ]}
            >
              <InputNumber placeholder={t('Enter credited amount')} bordered />
            </Form.Item>
            <Form.Item name="description">
              <TextArea placeholder={t('Add your description...')} rows={4} bordered />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-3">
            <Button theme="secondary" onClick={closeModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>

            <Button type="submit" isLoading={isLoading} isDisabled={isForbidden}>
              {t('Save', { ns: 'common' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
});
