import { api } from 'app/config/apiConfig';
import { Payment } from 'entities/Payment';
import { MarkAsPaidInvoiceParams } from '../model/types';

export const markAsPaidInvoiceApi = api.injectEndpoints({
  endpoints: (build) => ({
    markAsPaidInvoice: build.mutation<Payment, MarkAsPaidInvoiceParams>({
      query: (data) => ({
        url: `/billing-documents/invoices/${data.invoiceId}/mark-payment`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Invoices', 'My bookings', 'Deposit Payment'],
    }),
  }),
});

export const { useMarkAsPaidInvoiceMutation } = markAsPaidInvoiceApi;
