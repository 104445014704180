import { DirectionsRenderer, GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { FC, memo, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { ReactComponent as MapMarkerIcon } from 'shared/assets/icons/MapMarkerIcon.svg';
import { ReactComponent as MapMarkerSelectedIcon } from 'shared/assets/icons/MapMarkerSelectedIcon.svg';
import { GeolocationCoords } from 'app/types/common';
import { mapLibraries } from '../lib/constants';
import { Marker } from '../model/types';
import { MapMarker } from './MapMarker';

interface MapProps extends PropsWithChildren {
  mapId: string | undefined;
  center: Nullable<GeolocationCoords>;
  selectedMarkerId?: Nullable<string>;
  selectMarker?: (latitude: number, longitude: number, markerId: string) => void;
  zoom?: number;
  markers?: Marker[];
  withMarkerPopups?: boolean;
  directionResult?: Nullable<google.maps.DirectionsResult>;
  mapContainerClassName?: string;
  onSelectMarker?: (markerCoords: GeolocationCoords) => void;
}

export const Map: FC<MapProps> = memo((props) => {
  const {
    mapId,
    children,
    center,
    selectMarker,
    selectedMarkerId,
    zoom = 8,
    markers,
    directionResult,
    onSelectMarker,
    withMarkerPopups,
    mapContainerClassName,
  } = props;

  const [map, setMap] = useState<Nullable<google.maps.Map>>(null);

  useEffect(() => {
    selectedMarkerId && map?.setZoom(zoom * 2);
  }, [map, selectedMarkerId, zoom]);

  const { isLoaded } = useJsApiLoader({
    id: mapId,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '', // TODO move env constants to global config
    libraries: mapLibraries,
  });

  const mapOptions = useMemo<google.maps.MapOptions>(
    () => ({
      fullscreenControl: false,
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
    }),
    [],
  );

  const initMap = (map: google.maps.Map): void => {
    setMap(map);
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerClassName={mapContainerClassName}
          center={center || undefined}
          zoom={zoom}
          options={mapOptions}
          onLoad={initMap}
        >
          {directionResult && <DirectionsRenderer directions={directionResult} />}
          {markers?.map((marker, index) => (
            <MapMarker
              key={index}
              markerData={marker}
              icon={<MapMarkerIcon className="cursor-pointer" />}
              selectedIcon={<MapMarkerSelectedIcon className="cursor-pointer" />}
              selectedMarkerId={selectedMarkerId}
              selectMarker={selectMarker}
              onSelectMarker={onSelectMarker}
              withMarkerPopups={withMarkerPopups}
            />
          ))}
          {children}
        </GoogleMap>
      )}
    </>
  );
});
