import { api } from 'app/config/apiConfig';
import { USERS_PATH } from '../utils/constants';

export const sendLoginLinkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendLoginLink: builder.mutation<void, string>({
      query: (userId) => ({
        url: `${USERS_PATH}/send-login-link`,
        method: 'POST',
        body: { userId },
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const { useSendLoginLinkMutation } = sendLoginLinkApi;
