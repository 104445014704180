import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SendFranchiseSectionModalSchema } from '../types';
import { AppLanguage } from 'app/config/i18Config/types';
import { Section } from 'entities/User';

const initialState: SendFranchiseSectionModalSchema = {
  selectedEmail: null,
  selectedLanguage: null,
  sections: [],
};

const sendFranchiseSectionModalSlice = createSlice({
  name: 'sendFranchiseSectionModal',
  initialState,
  reducers: {
    setSelectedEmail: (state: SendFranchiseSectionModalSchema, action: PayloadAction<string>) => {
      state.selectedEmail = action.payload;
    },
    setSelectedLanguage: (state: SendFranchiseSectionModalSchema, action: PayloadAction<AppLanguage>) => {
      state.selectedLanguage = action.payload;
    },
    setSections: (state: SendFranchiseSectionModalSchema, action: PayloadAction<Section[]>) => {
      state.sections = action.payload;
    },
    resetModalState: (state: SendFranchiseSectionModalSchema) => {
      state.selectedEmail = null;
      state.selectedLanguage = null;
      state.sections = null;
    },
  },
});

export const { actions: sendFranchiseSectionModalActions, reducer: sendFranchiseSectionModalReducer } = sendFranchiseSectionModalSlice;
